import { FC, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { AddZonaToNodo, RemoveZonaToNodo } from '../../../services/zonasToNodos/zonasToNodos-service';
import notify from 'devextreme/ui/notify';
import { newZonasToNodosRequest } from '../../../types/requests/zonaToNodoRequest';
import { confirm } from 'devextreme/ui/dialog';

interface Props {
    zonaToNodoId: number,
    zonaId: number,
    nodoId: number,
    esOrigen: boolean
}

export const CheckBoxZonaXNodo : FC<Props> = ({ zonaToNodoId, zonaId, nodoId, esOrigen }) => {
    const [checked, setChecked] = useState(false);
    const [id, setId] = useState(0);
  
    useEffect(() => {
        if(zonaToNodoId !== 0) {
            setChecked(true);
            setId(zonaToNodoId);
        }        
    }, [zonaToNodoId])

    const { data: addZonaToNodo, loading: loadingPost, error: errorPost, sendPostSubmit } = AddZonaToNodo(newZonasToNodosRequest);
    const { data: removeZonaToNodo, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveZonaToNodo(id);

    useEffect(() => {
        if (addZonaToNodo){
            setChecked(true);
            setId(addZonaToNodo.id);
        }
           
    }, [addZonaToNodo]);

    useEffect(() => {
        if (errorPost){
            setChecked(false);
            notify(errorPost.message, 'Error', 2000);
        } 
    }, [errorPost])

    useEffect(() => {
        if (removeZonaToNodo){
            setChecked(false);
            setId(0);
        }           
    }, [removeZonaToNodo]);

    useEffect(() => {
        if (errorRemove){
            setChecked(true);
            notify(errorRemove.message, 'Error', 2000);
        }  
    }, [errorRemove]);
    
    function marcarZonas() {
        let result = confirm("<b>Se va a modificar la relacion en bbdd sin falta de darle a guardar, Desea continuar?</b>", "Confirmar Selección");
        result.then(dialogResult => {
            if (dialogResult) {
                if (checked) {
                    //Eliminar relacion
                    sendRemoveSubmit();
                }
                else {
                    //Añadir relacion
                    const nuevaRelacion = { zonaId: zonaId, nodoId: nodoId, esOrigen: esOrigen };
                    sendPostSubmit(nuevaRelacion);
                }
            }
        }); 
    }

    return (
        <>
            <CheckBox className="check_centrar_derecha" value={checked} onValueChange={marcarZonas} 
            disabled={loadingPost || loadingRemove} />
        </>
    );
}