import { FC, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  StringLengthRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './forgot-password.scss';
import { SingleCard } from '../layouts/single-card/single-card';
import { ForgotPassword } from '../../services/users/users-service';
import { ForgotPasswordRequest, newForgotPasswordRequest } from '../../types/requests/forgotPasswordRequest';
import { ForgotPasswordResponse } from '../../types/responses/forgotPasswordResponse'; 
import { Error } from '../loading/error';

const notificationText = 'Hemos enviado un enlace para restablecer tu contraseña. Comprueba tu bandeja de entrada.';

export const ForgotPasswordFC: FC = () => {
    const history = useHistory();
      
    const onSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        const nuevoForgotPasswordRequest: ForgotPasswordRequest = {
            email: String(datos['email'])            
        }
        sendPostSubmit(nuevoForgotPasswordRequest);
    }

    const { data, error, loading, sendPostSubmit } = ForgotPassword<ForgotPasswordResponse, ForgotPasswordRequest>(newForgotPasswordRequest);
    useEffect(() => {
        if(data){
            history.push('/');
            notify(notificationText, 'success', 4000);
        }
    }, [data, history]);
   
   
    if (error) {
        notify(error.message, 'error', 2000);
    }  

    return (
      <div className='centrado'>
          <div>
            {error && <Error error={error} />}
            {!data && (
            <SingleCard title="Introduzca Email" description="">
                <form className={"forgot-password"} onSubmit={onSubmit}>
                <Form formData={newForgotPasswordRequest} disabled={loading}>
                    <Item
                    dataField={"email"}
                    editorType={"dxTextBox"}
                    editorOptions={emailEditorOptions}
                    >
                        <StringLengthRule max={255} message={'El email no puede superar los 255 caracteres.'}></StringLengthRule>
                        <RequiredRule message="Email is required" />
                        <EmailRule message="Email is invalid" />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                    <ButtonOptions
                        elementAttr={submitButtonAttributes}
                        width={"100%"}
                        type={"default"}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                        {loading ? (
                            <LoadIndicator
                            width={"24px"}
                            height={"24px"}
                            visible={true}
                            />
                        ) : (
                            "Enviar"
                        )}
                        </span>
                    </ButtonOptions>
                    </ButtonItem>
                    <Item>
                    <div className={"forgot-link"}>
                        Return to <Link to={"/"}>Login</Link>
                    </div>
                    </Item>
                </Form>
                </form>
            </SingleCard>
            )}
        </div>
      </div>
    );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const submitButtonAttributes = { class: 'submit-button' };