// Clase propia para construir los metodos para las llamadas al ws
import { empresasUrl } from '../../utils/rutasllamadasapi';
import { EmpresaType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';

export const GetEmpresas = () => {    
    return useFetch<EmpresaType[]>(empresasUrl);
}

export const GetEmpresa = (id: number) => {    
    return useFetch<EmpresaType>(empresasUrl + id);
}
