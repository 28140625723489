import { FC, useEffect, useState } from "react";
import Accordion from 'devextreme-react/accordion';
import { CardContainer, Tarjeta, CustomTitle, CheckBoxPermisoXRol, TarjetaPermiso } from '../../components';
import { GetPermisos } from "../../services/permisos/permisos-service";
import { GetPermisosToRoles } from "../../services/permisosToRoles/permisosToRoles-service";
import { PermisoType } from "../../types";
import { Loading } from "../../components/loading/loading";
import { Error } from "../../components/loading/error";

interface Props {
    idRol: number
}

export const AcordeonPermisos: FC<Props> = ({idRol}) => {
    const [acordeon, setAcordeon] = useState([{}]);

    const { data: permisos, isPending: loadingPermisos, error: errPermisos } = GetPermisos();
    const { data: rolesToPermisos, isPending: loadingRolesToPermisos, error: errorRolesToPermisos } = GetPermisosToRoles();

    useEffect(() => {
        let tarjetasPermisos = null;
        if (permisos) {          
            const renderListadoPermisos = (permiso: PermisoType) => {
                let resultado = null;
                let rolesToPermisosPermiso = rolesToPermisos?.find(x => x.permisoId === permiso.id && x.rolId === idRol);
                resultado = <Tarjeta key={permiso.id}>
                    <TarjetaPermiso permiso={permiso} isRelacion={true} />
                    <CheckBoxPermisoXRol rolToPermisoId={ rolesToPermisosPermiso?.id || 0 }                 
                                         permisoId={permiso.id} 
                                         rolId={idRol}
                    /> 
                </Tarjeta>
                return resultado;
            };
            tarjetasPermisos = permisos.map(permiso => renderListadoPermisos(permiso));
        }
        const componentes = [{
            'Title': 'Permisos',
            'children': tarjetasPermisos
        }];
        setAcordeon(componentes);
    }, [permisos, rolesToPermisos, idRol])

    return (
        <div>
            {(loadingPermisos || loadingRolesToPermisos) && <Loading />}
            {errPermisos && <Error error={errPermisos} />}
            {errorRolesToPermisos && <Error error={errorRolesToPermisos} />}

            {(permisos && rolesToPermisos) &&
                <div id="accordion">
                    <Accordion
                        dataSource={acordeon}
                        itemRender={CardContainer}
                        itemTitleRender={CustomTitle}
                        selectedItems={acordeon}
                        multiple={true}
                        collapsible={false}                        
                        //animationDuration={400}
                    />
                </div>
            }
        </div>
    );
}