// Clase propia para construir los metodos para las llamadas al ws
import { nodosToLectoresUrl } from '../../utils/rutasllamadasapi';
import { NodoToLectorType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { NodoToLectorRequest } from '../../types/requests/nodoToLectorRequest';

export const GetNodosToLectores = () => {
    return useFetch<NodoToLectorType[]>(nodosToLectoresUrl);
}

export const AddNodosToLectores = (nodoToLector: NodoToLectorRequest) => {
    return usePostHook<NodoToLectorType, NodoToLectorRequest>(nodosToLectoresUrl, nodoToLector);
}

export const UpdateNodosToLectores = (id: number, nodoToLector: NodoToLectorRequest) => {
    return usePutHook<NodoToLectorType, NodoToLectorRequest>(nodosToLectoresUrl + id, nodoToLector);
}

export const RemoveNodosToLectores = (id: number) => {
    return useDeleteHook<number>(nodosToLectoresUrl + id);
}