import { FC, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import usuarioLogo from '../../pages/perfil/usuario.png';
import { GetUserToken } from '../../services/users/users-service';
import { Loading } from '../loading/loading';
import { Error } from '../loading/error';
import { StoreContext } from '../../contexts/context';

interface Props {
  menuMode: any
}

const UserPanel: FC<Props> = ({ menuMode }) => {
   
    const history = useHistory();     
    
    const { useUsuarioSlice, useTokenSlice } = useContext(StoreContext);
    const [ , dispatchUser ] = useUsuarioSlice;
    const [ , dispatchToken ] = useTokenSlice;   
    
    const {data:usuario, isPending, error} = GetUserToken();
    useEffect(()=> {
        if(usuario)
        {           
            dispatchUser({type: "Add", payload: usuario});  
        }
    }, [usuario, dispatchUser]);
 
    function navigateToProfile() {      
        history.push("/usuario/" + usuario?.id + "/");
    }

    function desloguearse() {        
        dispatchToken({type: "Delete" });
        history.push('/');
    }

    const menuItems = ([
    {
        text: 'Perfil',
        icon: 'user',
        onClick: navigateToProfile
    },
    {
        text: 'Logout',
        icon: 'runner',
        onClick: desloguearse
    }
        // eslint-disable-next-line     
    ]);

  return (
    <div>
    { isPending && <Loading />}
    { error && <Error error={error} />}
    { usuario && (
      <div className={'user-panel'}>
        <div className={'user-info'}>
          <div className={'image-container'}>
            <div
              style={{
                background: `url(${usuarioLogo}) no-repeat #fff`,            
                backgroundSize: 'cover'
              }}
              className={'user-image'} />
          </div>
          {menuMode === 'context' && (
          <div className={'user-name'} >{usuario?.email}</div>
          )}
          {menuMode !== 'context' && (
          <div className={'user-name-negro'} >{usuario?.email}</div>
          )}
        </div>

        {menuMode === 'context' && (
          <ContextMenu
            items={menuItems}
            target={'.user-button'}
            showEvent={'dxclick'}
            width={210}
            cssClass={'user-menu'}
          >
            <Position my={'top center'} at={'bottom center'} />
          </ContextMenu>
        )}
        {menuMode === 'list' && (
          <List className={'dx-toolbar-menu-action'} items={menuItems} />
        )}
      </div>
    )}
    
    </div>
  );
}

export default UserPanel;