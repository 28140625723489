import { SelectBox } from "devextreme-react";
import { FC, useContext, useEffect } from "react";
import { StoreContext } from "../../contexts/context";
import { CambiarEmpresaUsuario } from '../../services/users/users-service';
import { GetEmpresas } from '../../services/empresas/empresas-service';
import './suerpadminempresaselector.scss';
import { Loading } from "../../components/loading/loading";
import { Error } from "../../components/loading/error";
import { useHistory } from "react-router-dom";

export const SuperAdminEmpresaSelector: FC = () => {    
   
    const history = useHistory();  
    const { useUsuarioSlice, useTokenSlice } = useContext(StoreContext);
    const [usuario, dispatchUsuario] = useUsuarioSlice;
    const [ , dispatchToken ] = useTokenSlice;
    const {data, isPending, error} = GetEmpresas();
    const {loading: loadingUsuario, error: errUsuario, sendPutSubmit} = CambiarEmpresaUsuario(usuario.id, usuario);

    useEffect(() => {
        if (error) {
            dispatchToken({type: "Delete" });         
            history.push('/');
        }
       
    }, [error, dispatchToken, history]);

    const isSuperAdmin = usuario.rolId === 1;
    if (!isSuperAdmin)
        return null;        

    const onEmpresaSelected = (e: any) => {      
        const nuevoUsuario = usuario;
        nuevoUsuario.empresaId = e.value;        
        sendPutSubmit(nuevoUsuario);
        dispatchUsuario({ type: "Add", payload: nuevoUsuario });
        history.push('/');
    };

    return (   
        <div>     
        { (isPending || loadingUsuario) && <Loading />}
        { error  && <Error error={error} />}
        { errUsuario  && <Error error={errUsuario} />}
        { data &&
            <div className="dx-field">
                {
                //<div className="dx-field-label" style={{ color: "white" }}>    
                    //Cambia la empresa activa
                //</div>
                }
                <div className="dx-field-value">
                    <SelectBox dataSource={data}
                        displayExpr="nombre"
                        valueExpr="id"
                        defaultValue={usuario.empresaId}
                        onValueChanged={onEmpresaSelected}
                        placeholder="Loading..."                         
                        
                    />
                </div>
            </div>
        }
        </div>
    );

}

