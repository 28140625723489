export interface ChangePasswordRequest {  
    actualPassword: string,
    newPassword: string,
    confirmPassword: string    
}
  
export const newChangePasswordRequest: ChangePasswordRequest = {  
    actualPassword: '',
    newPassword: '',
    confirmPassword: ''
}