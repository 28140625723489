import { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,  
  StringLengthRule,
  PatternRule,
  GroupItem 
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './change-password.scss';
import { ChangePassword } from '../../services/users/users-service';
import { ChangePasswordRequest, newChangePasswordRequest } from '../../types/requests/changePasswordRequest';
import { ChangePasswordResponse } from '../../types/responses/changePasswordResponse';
import { StoreContext } from '../../contexts/context';
import { confirm } from 'devextreme/ui/dialog';
import iconoOjo from '../../components/layouts/imgIconosTarjetas/iconoOjo.png';
import iconoOjoOculto from '../../components/layouts/imgIconosTarjetas/iconoOjoOculto.png';

const notificationText = 'La contraseña se ha cambiado correctamente. Vuelva a iniciar sesion.';

export const ChangePasswordFC: FC = () => {  
    const history = useHistory();

    const { useTokenSlice } = useContext(StoreContext);
    const [ , dispatchToken ] = useTokenSlice;

    const [showPasswordActual, setShowPasswordActual] = useState(false);
    const [iconoBotonMostrarPasswordActual, setIconoBotonMostrarPasswordActual] = useState(iconoOjoOculto);
    
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [iconoBotonMostrarPasswordNew, setIconoBotonMostrarPasswordNew] = useState(iconoOjoOculto);
    
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [iconoBotonMostrarPasswordConfirm, setIconoBotonMostrarPasswordConfirm] = useState(iconoOjoOculto);

    const onSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);       

        const nuevoChangePasswordRequest: ChangePasswordRequest = {
            actualPassword: String(datos['actualPassword']), 
            newPassword: String(datos['newPassword']),
            confirmPassword: String(datos['confirmPassword']),
        }

        // Informar que se va a cerrar sesion
        let result = confirm("<b>Se va a cerrar sesion para aplicar los cambios, Desea continuar?</b>", "Confirmar cambiar contraseña");
        result.then(dialogResult => {
            if (dialogResult) {
                if (nuevoChangePasswordRequest.newPassword === nuevoChangePasswordRequest.confirmPassword)
                {
                    sendPostSubmit(nuevoChangePasswordRequest);
                }
                else
                {
                    notify('Debe de confirmar correctamente la contraseña.', 'error', 4000);
                }
            } 
        });      
       
    }

    newChangePasswordRequest.actualPassword = '';
    newChangePasswordRequest.confirmPassword = '';
    newChangePasswordRequest.newPassword = '';
    
    const { data, error, loading, sendPostSubmit } = ChangePassword<ChangePasswordResponse, ChangePasswordRequest>(newChangePasswordRequest);
    useEffect(() => {
        if(data) {
            notify(notificationText, 'success', 4000);
            
            dispatchToken({type: "Delete" });
            
            history.push('/');
        }
    }, [data, history, dispatchToken]);    
   
    if (error) {
        notify(error.message, 'error', 4000);
    }  

    function mostrarPasswordActual()
    {
        if(showPasswordActual)
            setIconoBotonMostrarPasswordActual(iconoOjoOculto);
        else
            setIconoBotonMostrarPasswordActual(iconoOjo);
        setShowPasswordActual(!showPasswordActual);    
    }

    function mostrarPasswordNew()
    {
        if(showPasswordNew)
            setIconoBotonMostrarPasswordNew(iconoOjoOculto);
        else
            setIconoBotonMostrarPasswordNew(iconoOjo);
        setShowPasswordNew(!showPasswordNew);    
    }

    function mostrarPasswordConfirm()
    {
        if(showPasswordConfirm)
            setIconoBotonMostrarPasswordConfirm(iconoOjoOculto);
        else
            setIconoBotonMostrarPasswordConfirm(iconoOjo);
        setShowPasswordConfirm(!showPasswordConfirm);    
    }

    return (
      <div>
        {!data && (       
        <div className={'dx-card content'} >
            <form className={"change-password"} onSubmit={onSubmit}>
              <Form formData={newChangePasswordRequest} disabled={loading}>
              <GroupItem colCount={7} >
                <Item colSpan={6}
                  dataField={"actualPassword"}
                  editorType={"dxTextBox"}
                  editorOptions={showPasswordActual ? { stylingMode: 'filled', placeholder: 'Actual Contraseña', mode: 'text' }: {stylingMode: 'filled', placeholder: 'Actual Contraseña', mode: 'password'}}                  
                >                  
                  <StringLengthRule min={6} max={255} message='La nueva contraseña debe ser mayor a 6 caracteres y menor de 255' ></StringLengthRule>
                  <RequiredRule message="Contraseña requerida" />
                  <Label visible={false} />
                </Item>
                <ButtonItem colSpan={1}>
                  <ButtonOptions icon={iconoBotonMostrarPasswordActual} onClick={mostrarPasswordActual} />
                </ButtonItem>
              </GroupItem>
              <GroupItem colCount={7} >
                <Item colSpan={6}
                  dataField={"newPassword"}
                  editorType={"dxTextBox"}
                  editorOptions={showPasswordNew ? { stylingMode: 'filled', placeholder: 'Nueva Contraseña', mode: 'text' } : {stylingMode: 'filled', placeholder: 'Nueva Contraseña', mode: 'password'}}
                >
                  <StringLengthRule min={6} max={255} message='La nueva contraseña debe ser mayor a 6 caracteres y menor de 255' ></StringLengthRule>
                  <PatternRule pattern={"(?=.*[0-9])(?=.*[@$!%?&.,-])(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"} message={"Debe tener al menos una mayúscula, una minúscula, un numero y un signo"}></PatternRule>
                  <RequiredRule message="Contraseña requerida" />                  
                  <Label visible={false} />
                </Item>
                <ButtonItem colSpan={1}>
                  <ButtonOptions icon={iconoBotonMostrarPasswordNew} onClick={mostrarPasswordNew} />
                </ButtonItem>
              </GroupItem>
              <GroupItem colCount={7} >
                <Item colSpan={6}
                  dataField={"confirmPassword"}
                  editorType={"dxTextBox"}
                  editorOptions={showPasswordConfirm ? { stylingMode: 'filled', placeholder: 'Confirmar Contraseña', mode: 'text' } : { stylingMode: 'filled', placeholder: 'Confirmar Contraseña', mode: 'password' }}
                >
                  <StringLengthRule min={6} max={255} message='La confirmación de la contraseña debe ser mayor a 6 caracteres y menor de 255' ></StringLengthRule>                  
                  <PatternRule pattern={"(?=.*[0-9])(?=.*[@$!%?&.,-])(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"} message={"Debe tener al menos una mayúscula, una minúscula, un numero y un signo"}></PatternRule>
                  <RequiredRule message="Contraseña requerida" />                  
                  <Label visible={false} />
                </Item>
                <ButtonItem colSpan={1}>
                  <ButtonOptions icon={iconoBotonMostrarPasswordConfirm} onClick={mostrarPasswordConfirm} />
                </ButtonItem>
              </GroupItem>
                <ButtonItem>
                  <ButtonOptions
                    elementAttr={submitButtonAttributes}
                    width={"100%"}
                    type={"default"}
                    useSubmitBehavior={true}
                  >
                    <span className="dx-button-text">
                      {loading ? (
                        <LoadIndicator
                          width={"24px"}
                          height={"24px"}
                          visible={true}
                        />
                      ) : (
                        "Cambiar"
                      )}
                    </span>
                  </ButtonOptions>
                </ButtonItem>               
              </Form>
            </form> 
        </div>
        )}
      </div>
    );
}

// const actualPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Actual Contraseña', mode: 'password' };
// const newPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Nueva Contraseña', mode: 'password' };
// const confirmPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirmar Contraseña', mode: 'password' };
const submitButtonAttributes = { class: 'submit-button' };