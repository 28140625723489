import React, { createContext } from 'react'
import { ActionHovered, ActionUsuario, ActionToken } from '../reducers/actions/action-types';
import { HoveredType } from '../types/hovered';
import { UsuarioType } from '../types/usuarios';

export const StoreContext = createContext<{  
    useHoveredSlice: [HoveredType, React.Dispatch<ActionHovered>],
    useUsuarioSlice: [UsuarioType, React.Dispatch<ActionUsuario>],
    useTokenSlice: [string, React.Dispatch<ActionToken>] 
}>({   
    useHoveredSlice: [{} as HoveredType, () => { }],
    useUsuarioSlice: [{} as UsuarioType, () => { }],
    useTokenSlice: [{} as string, ()=> '']    
})