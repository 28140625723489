import { FC, useEffect, useState } from 'react';
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    NumericRule
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './datosGpo.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useParams, useHistory } from "react-router-dom";
import iconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import { GetLectores } from '../../services/lectores/lectores-service';
import { GetNodos } from '../../services/nodos/nodos-service';
import { GetGpo, UpdateGpo, AddGpo, RemoveGpo } from '../../services/gpos/gpos-service';
import { LectorType, NodoType } from '../../types';
import { GpoRequest, newGpoRequest } from '../../types/requests/gpoRequest';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

interface Params {
    id: string
}

export const DatosGpo: FC = () => {

    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;
    const { data: gpo, isPending, error } = GetGpo(idNumero);
    const { data: lectores, isPending: loadingLectores, error: errLectores } = GetLectores();
    const [lectoresOptions, setLectoresOptions] = useState({});

    useEffect(() => {
        let dataLectores: LectorType[] = [];
        loadingLectores && (dataLectores = []);
        errLectores && (dataLectores = []);
        lectores && (dataLectores = lectores);
        setLectoresOptions({
            dataSource: dataLectores,
            valueExpr: "id",
            displayExpr: "nombre",
            disabled: false,
            placeholder: loadingLectores
                ? "Loading..."
                : errLectores
                    ? "¡Error!"
                    : ""
        });
    }, [lectores, loadingLectores, errLectores]);

    const { data: nodos, isPending: loadingNodos, error: errNodos } = GetNodos();
    const [nodosOptions, setNodosOptions] = useState({});

    useEffect(() => {
        let dataNodos: NodoType[] = [];
        loadingNodos && (dataNodos = []);
        errNodos && (dataNodos = []);
        nodos && (dataNodos = nodos);
        setNodosOptions({
            dataSource: dataNodos,
            valueExpr: "id",
            displayExpr: "nombre",
            disabled: false,
            placeholder: loadingNodos
                ? "Loading..."
                : errNodos
                    ? "¡Error!"
                    : ""
        });
    }, [nodos, loadingNodos, errNodos]);


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);
      
        const gpoNuevo: GpoRequest = {
            puerto: Number(datos["puerto"]),
            lectorId: Number(datos["lectorId"]),
            nodoId: Number(datos["nodoId"])
        }

        if (id != null) {
            sendPutSubmit(gpoNuevo);
        } else {
            sendPostSubmit(gpoNuevo);
        }
    };

    const { data: updateGpo, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdateGpo(idNumero, newGpoRequest);//formData.current);
    const { data: addGpo, loading: loadingPost, error: errorPost, sendPostSubmit } = AddGpo(newGpoRequest);
    const { data: removeGpo, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveGpo(idNumero);

    useEffect(() => {
        if (updateGpo)
            //history.push('/datosGenerales');
            history.goBack();
    }, [updateGpo, history]);

    useEffect(() => {
        if (addGpo)
            history.push('/datosGenerales');
    }, [addGpo, history]);

    useEffect(() => {
        if (removeGpo)
            history.push('/datosGenerales');
    }, [removeGpo, history]);
   
    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])

    function eliminar() {
        let result = confirm("<b>Se va a borrar el gpo, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
          });       
    }

    return (
        <div>
            {isPending && <Loading />}
            {error && <Error error={error} />}
            {gpo && (
                <div>
                    <h2 className={'content-block'}>Gpo</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={gpo}>
                            <GroupItem colCount={4}>
                                <SimpleItem render={logoGpo}>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField="puerto" editorType={'dxNumberBox'} 
                                            editorOptions={{max:999999}}>
                                        <NumericRule/>
                                        <RequiredRule message="El puerto es requerido" />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="lectorId"
                                        editorType="dxSelectBox"
                                        editorOptions={lectoresOptions} >
                                        <RequiredRule message="El lector es requerido" />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="nodoId"
                                        editorType="dxSelectBox"
                                        editorOptions={nodosOptions} >
                                        <RequiredRule message="El nodo es requerido" />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem colCount={12}>
                                <EmptyItem colSpan={10} />
                                <ButtonItem>
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPut || loadingPost)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem>
                                    <ButtonOptions icon={iconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove} />
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            )}
        </div>
    );
}

function logoGpo() {
    return (
        <div className="form-logo-gpo"></div>
    );
}