import './tarjetaAniadir.scss';
import IconoAniadir from '../imgIconosTarjetas/iconoAniadir.png';
import { Link} from "react-router-dom";
import { FC } from 'react';

interface Props {
    tipo: string
}

export const TarjetaAniadir: FC<Props> = ({ tipo }) => { 
   
    const destino = () => getDestino(tipo);

    return (
        <div>
            <Link to={destino}>
                <img className="botonAniadir" src={IconoAniadir} alt="" />
            </Link>
        </div>
    );
};

function getDestino(tipo: string) {
    switch (tipo) {
        case "Nodos":
            return '/nodo';
        case "Lectores":
            return '/lector';
        case "Zonas":
            return '/zona';
        case "Antenas":
            return '/antena';
        case "Gpis":
            return '/gpi';
        case "Gpos":
            return '/gpo';
        case "Users":
            return '/usuario';
        case "Roles":
            return '/roles';
        case "Permisos":
            return '/permisos';
        default:
            return '';
    }
}