import { FC, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { AddPermisoToRol, RemovePermisoToRol } from '../../../services/permisosToRoles/permisosToRoles-service';
import { newPermisoToRolRequest } from '../../../types/requests/permisoToRolRequest';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

interface Props {
    rolToPermisoId: number
    permisoId: number
    rolId: number
}

export const CheckBoxPermisoXRol: FC<Props> = ({ rolToPermisoId, permisoId, rolId }) => {

    const [checked, setChecked] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        if(rolToPermisoId !== 0) {
            setChecked(true);
            setId(rolToPermisoId);
        }        
    }, [rolToPermisoId]);
    
    const { data: addPermisoToRol, loading: loadingPost, error: errorPost, sendPostSubmit } = AddPermisoToRol(newPermisoToRolRequest);
    const { data: removePermisoToRol, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemovePermisoToRol(id);

    useEffect(() => {
        if (addPermisoToRol){
            setChecked(true);
            setId(addPermisoToRol.id);
        }
           
    }, [addPermisoToRol]);

    useEffect(() => {
        if (errorPost){
            setChecked(false);
            notify(errorPost.message, 'Error', 2000);
        } 
    }, [errorPost]);

    useEffect(() => {
        if (removePermisoToRol){
            setChecked(false);
            setId(0);
        }           
    }, [removePermisoToRol]);

    useEffect(() => {
        if (errorRemove){
            setChecked(true);
            notify(errorRemove.message, 'Error', 2000);
        }  
    }, [errorRemove]);

    function marcarPermisos() {       
        let result = confirm("<b>Se va a modificar la relacion en bbdd sin falta de darle a guardar, Desea continuar?</b>", "Confirmar Selección");
        result.then(dialogResult => {
            if (dialogResult) {
                if (checked) {
                    sendRemoveSubmit();
                }
                else {
                    const nuevaRelacion = { permisoId: permisoId, rolId: rolId };
                    sendPostSubmit(nuevaRelacion)
                }
            } 
        }); 
    }

    return (
        <>
            <CheckBox className="check_centrar_derecha" value={checked} onValueChange={marcarPermisos} 
            disabled={loadingPost || loadingRemove} />
        </>
    );
}


/*

    useEffect(() => {
        if (permisosToRoles)    {
            const datos = permisosToRoles.find(x => x.permisoid === permisoId && x.rolid === rolId);
            if (datos) {
                setChecked(true);
            }
            else {
                setChecked(false);
            }
        }
    }, [permisosToRoles])



*/