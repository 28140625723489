import { FC } from 'react';

interface Props {
    Title: string
}

export const CustomTitle: FC<Props> = ({Title}) => {
    return (
        <h5>{Title}</h5>
    );
}