// Clase propia para construir los metodos para las llamadas al ws
import { zonasUrl } from '../../utils/rutasllamadasapi';
import { ZonaType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { ZonaRequest } from '../../types/requests/zonaRequest';

export const GetZonas = () => {
    return useFetch<ZonaType[]>(zonasUrl);
}

export const GetZona = (id: number) => {
    return useFetch<ZonaType>(zonasUrl + id);
}

export const AddZona = (zona: ZonaRequest) => {
    return usePostHook<ZonaType, ZonaRequest>(zonasUrl, zona);
}

export const UpdateZona = (id: number, zona: ZonaRequest) => {
    return usePutHook<ZonaType, ZonaRequest>(zonasUrl + id, zona);
}

export const RemoveZona = (id: number) => {
    return useDeleteHook<number>(zonasUrl + id);
}