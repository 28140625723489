import { FC, useEffect, useState } from "react";
import { CardContainer, Tarjeta, CustomTitle, TarjetaRol, CheckBoxPermisoXRol } from '../../components';
import Accordion from 'devextreme-react/accordion';
import { GetRoles } from "../../services/roles/roles-service";
import { GetPermisosToRoles } from "../../services/permisosToRoles/permisosToRoles-service";
import { RolType } from "../../types";
import { Loading } from "../../components/loading/loading";
import { Error } from '../../components/loading/error';

interface Props {
    idPermiso: number
}

export const AcordeonRoles: FC<Props> = ({idPermiso}) => {

    const [acordeon, setAcordeon] = useState([{}]);
    
    const { data: roles, isPending: loadingRoles, error: errorRoles } = GetRoles();
    const { data: rolesToPermisos, isPending: loadingRolesToPermisos, error: errorRolesToPermisos } = GetPermisosToRoles();

    useEffect(() => {
        let tarjetasRoles = null;
        if(roles) {
            const renderListadoRoles = (rol: RolType) => {
                let resultado = null;
                let rolesToPermisosPermiso = rolesToPermisos?.find(x => x.permisoId === idPermiso && x.rolId === rol.id);
                resultado = <Tarjeta key={rol.id}>
                    <TarjetaRol rol={rol} isRelacion={true} />
                    <CheckBoxPermisoXRol rolToPermisoId={ rolesToPermisosPermiso?.id || 0 }                 
                                         permisoId={idPermiso} 
                                         rolId={rol.id} 
                    />
                </Tarjeta>
                return resultado;
            };         
            tarjetasRoles = roles.map(rol => renderListadoRoles(rol));
        }        
        const componentes = [{
            'Title': 'Roles',
            'children': tarjetasRoles
        }];
        setAcordeon(componentes);
    }, [roles, rolesToPermisos, idPermiso])

    return (
        <div>
        {(loadingRoles || loadingRolesToPermisos) && <Loading />}
        {errorRoles && <Error error={errorRoles} />}
        {errorRolesToPermisos && <Error error={errorRolesToPermisos} />}

        { (roles && rolesToPermisos) && 
            <div id="accordion">
                <Accordion
                    dataSource={acordeon}
                    itemRender={CardContainer}
                    itemTitleRender={CustomTitle}
                    selectedItems={acordeon}
                    multiple={true}
                    collapsible={false}
                    //animationDuration={400}
                />
            </div>
        }
        </div>        
    );
}
