export interface UsuarioRequest
{
  userName: string,
  password: string, 
  email: string,
  firstname: string,
  lastName: string,
  rolId: number
}

export const newUsuarioRequest : UsuarioRequest = { userName: '', password: '', email: '', firstname: '', lastName: '', rolId: 0};
