export interface UsuarioType {
    id: number
    userName: string
    password: string
    email: string
    firstname: string
    lastName: string
    empresaId: number
    rolId: number
}

export const newUsuario : UsuarioType = {id: 0, userName: '', password: '', email: '', firstname: '', lastName: '', empresaId: 0, rolId: 0};