import { FC, useEffect } from 'react';
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    StringLengthRule
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './datosZona.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useParams, useHistory } from "react-router-dom";
import IconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import { AddZona, GetZona, RemoveZona, UpdateZona } from '../../services/zonas/zonas-service';
import { newZonasRequest, ZonaRequest } from '../../types/requests/zonaRequest';
import notify from 'devextreme/ui/notify';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import { AcordeonNodosZona } from './acordeonNodosZona';
import { confirm } from 'devextreme/ui/dialog';

interface Params {
    id: string
}

export const DatosZona: FC = () => {    
    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;

    const { data: zona, isPending, error } = GetZona(idNumero);
      
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);   

        const zonaNueva: ZonaRequest = {
            nombre: String(datos["nombre"]),
            descripcion: String(datos["descripcion"])
        }

        if (id != null) {
          sendPutSubmit(zonaNueva);           
        } else {
           sendPostSubmit(zonaNueva);
        }       
    };    

    const { data: updateZona, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdateZona(idNumero, newZonasRequest);//formData.current);
    const { data: addZona, loading: loadingPost, error: errorPost, sendPostSubmit } = AddZona(newZonasRequest);
    const { data: removeZona, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveZona(idNumero);

    useEffect(() => {
        if (updateZona)
            history.push('/datosGenerales');
    }, [updateZona, history]);

    useEffect(() => {
        if (addZona)
            history.push('/zona/' + addZona.id);
    }, [addZona, history]);

    useEffect(() => {
        if (removeZona)
            history.push('/datosGenerales');
    }, [removeZona, history]);

    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])
   
    function eliminar() {
        let result = confirm("<b>Se va a borrar la zona, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
          });       
    }

    return (
        <div>
            {isPending && <Loading />}
            {error && <Error error={error} />}
            {zona && (
                <div>
                    <h2 className={'content-block'}>Zona</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={zona}>
                            <GroupItem colCount={4}>
                                <SimpleItem render={logoZona}>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField="nombre" >
                                        <StringLengthRule max={255} message={'El nombre no puede ser mayor a 255 caracteres'} ></StringLengthRule>
                                        <RequiredRule message="El nombre es requerido" />
                                    </SimpleItem>
                                    <SimpleItem dataField="descripcion" name="descripcion" >
                                        <StringLengthRule max={255} message={'La descripcion no puede ser mayor a 255 caracteres'} ></StringLengthRule>
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem colCount={12} >
                                <EmptyItem colSpan={10} />
                                <ButtonItem>
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPost || loadingPut)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem>
                                    <ButtonOptions icon={IconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove} />
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                    </form>
                    <br />
                    { idNumero!==0 && <AcordeonNodosZona idZona={idNumero} /> }
                </div>
            )}
        </div>
    );
}

function logoZona() {
    return (
        <div className="form-logo-zona"></div>
    );
}