import { FC, useEffect, useState, useContext } from 'react';
import './datosUsuario.scss';
import { useHistory, useParams } from 'react-router-dom';
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    Item,
    StringLengthRule,
    EmailRule,
    PatternRule
} from 'devextreme-react/form';

import LoadIndicator from 'devextreme-react/load-indicator';
import { Label } from 'devextreme-react/data-grid';
import { RolType } from '../../types';
import { AddUser, GetUsuario, RemoveUser, UpdateUser } from '../../services/users/users-service';
import { GetRoles } from '../../services/roles/roles-service';
import notify from 'devextreme/ui/notify';
import { newUsuarioRequest, UsuarioRequest } from '../../types/requests/usuarioRequest';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import iconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import iconoOjo from '../../components/layouts/imgIconosTarjetas/iconoOjo.png';
import iconoOjoOculto from '../../components/layouts/imgIconosTarjetas/iconoOjoOculto.png';
import { confirm } from 'devextreme/ui/dialog';
import { StoreContext } from '../../contexts/context';

interface Params {
    id: string
}

export const DatosUsuario: FC = () => {  
    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;
    const { data: usuario, isPending: loadingUsuario, error: errUsuario } = GetUsuario(idNumero);
    const { data: roles, isPending: loadingRoles, error: errRoles } = GetRoles();

    const { useUsuarioSlice, useTokenSlice } = useContext(StoreContext);
    const [ userCon,  ] = useUsuarioSlice;
        
    const [ , dispatchToken ] = useTokenSlice;

    const [ cambiadoRol, setCambiadoRol] = useState(false);
    const { data: usuariobbdd } = GetUsuario(idNumero);
    
    const [showPassword, setShowPassword] = useState(false);
    const [iconoBotonMostrarPassword, setIconoBotonMostrarPassword] = useState(iconoOjoOculto);

    const [rolesOptions, setRolesOptions] = useState({});
    useEffect(() => {
        let dataRoles: RolType[] = [];
        loadingRoles && (dataRoles = []);
        errRoles && (dataRoles = []);
        roles && (dataRoles = roles);
        setRolesOptions({
            dataSource: dataRoles,
            valueExpr: "id",
            displayExpr: "nombre",
            disabled: false,
            placeholder: loadingRoles
                ? "Loading..."
                : errRoles
                    ? "¡Error!"
                    : ""
        });
    }, [roles, loadingRoles, errRoles]);
    
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);
        
        const usuarioNuevo: UsuarioRequest = {            
            email: String(datos["email"]),            
            firstname: String(datos["firstname"]),
            lastName: String(datos["lastName"]),
            password: String(datos["password"]),
            rolId: Number(datos["rolId"]),
            userName: String(datos["userName"]),
        }

        if (idNumero !== 0) {
            // Se comprueba si se ha cambiado el rol y se cierra sesion
            if (usuariobbdd?.rolId !== usuarioNuevo.rolId)
            {
                if (userCon.id === idNumero)
                {
                    let result = confirm("<b>Se va a cerrar sesion para aplicar el cambio de rol, Desea continuar?</b>", "Confirmar cambiar rol");
                    result.then(dialogResult => {
                        if (dialogResult) {                        
                            sendPutSubmit(usuarioNuevo);               
                            setCambiadoRol(true);
                        } 
                    });
                }
                else
                {
                    sendPutSubmit(usuarioNuevo);
                    setCambiadoRol(false);
                }    
            }       
            else
            {
                sendPutSubmit(usuarioNuevo);
                setCambiadoRol(false);
            }
        }
        else {
           sendPostSubmit(usuarioNuevo);
           setCambiadoRol(false);
        }
    }

    const { data: updateUsuario, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdateUser(idNumero, newUsuarioRequest);
    const { data: addUsuario, loading: loadingPost, error: errorPost, sendPostSubmit } = AddUser(newUsuarioRequest);
    const { data: removeUsuario, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveUser(idNumero);

    useEffect(() => {
        if (updateUsuario)
        {
            if (cambiadoRol)
            {
                dispatchToken({type: "Delete" });            
                history.push('/');
            }
            else
            {
                history.push('/datosUsuarios');
            } 
        }       
    }, [updateUsuario, history, cambiadoRol, dispatchToken]);

    useEffect(() => {
        if (addUsuario)
            history.push('/datosUsuarios');
    }, [addUsuario, history]);

    useEffect(() => {
        if (removeUsuario)
            history.push('/datosUsuarios');
    }, [removeUsuario, history]);
    
    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])
    
    function eliminar() {
        let result = confirm("<b>Se va a borrar el usuario, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
          });       
    }

    function cambiarPassword() {
        if (userCon.rolId === 1 && userCon.id !== usuario?.id)
        {   
            history.push('/changePasswordSuper/' + usuario?.id);
        }
        else
        {
            history.push('/changePassword');
        }
    }
    
    function mostrarPassword()
    {
        if(showPassword)
            setIconoBotonMostrarPassword(iconoOjoOculto);
        else
            setIconoBotonMostrarPassword(iconoOjo);
        setShowPassword(!showPassword);    
    }

    return (
        <div>
            {loadingUsuario && <Loading />}
            {errUsuario && <Error error={errUsuario} />}            
            {usuario && (
                <div>
                    <h2 className={'content-block'}>Usuario</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={usuario}>
                            <GroupItem colCount={4}>
                                <SimpleItem>
                                    <div className="form-logo-usuario"></div>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <Item dataField={"id"}
                                        editorType={"dxTextBox"}                                        
                                        visible={false}
                                        isRequired={true}
                                    />
                                    <SimpleItem dataField="firstname"
                                        editorType={"dxTextBox"} >
                                        <StringLengthRule max={50} message='El nombre no puede ser mayor a 50 caracteres' ></StringLengthRule>
                                        <Label text="Nombre" />                                        
                                    </SimpleItem>
                                    <SimpleItem dataField="lastName" >
                                        <StringLengthRule max={50} message='El apellido no puede ser mayor a 50 caracteres' ></StringLengthRule>
                                        <Label text="Apellidos" />
                                    </SimpleItem> 
                                    { userCon.rolId !== 3 &&  //3 son tipo usuario                               
                                        <SimpleItem
                                            dataField="rolId"
                                            editorType="dxSelectBox"
                                            editorOptions={rolesOptions}
                                        >
                                            <Label text="Rol" />
                                            <RequiredRule message="El rol es requerido" />
                                        </SimpleItem>
                                    }
                                    <SimpleItem dataField="userName" >                                        
                                        <StringLengthRule max={10} message='El username no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                        <Label text="Usuario" />
                                        <RequiredRule message="El usuario es requerido" />                                        
                                    </SimpleItem>
                                    <SimpleItem dataField="email" >
                                        <StringLengthRule max={255} message='El email no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                        <RequiredRule message="El email es requerido" />
                                        <EmailRule message="Formato de email no valido" />
                                    </SimpleItem>
                                    { idNumero === 0 &&
                                    <GroupItem colCount={12}>
                                        <SimpleItem colSpan={11} dataField="password" editorOptions={showPassword ? {mode: 'text'} : {mode: 'password'}} >
                                            <StringLengthRule min={6} max={255} message='La password debe ser mayor a 6 caracteres y menor de 255' ></StringLengthRule>
                                            <PatternRule pattern={"(?=.*[0-9])(?=.*[@$!%?&.,-])(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"} message={"Debe tener al menos una mayúscula, una minúscula, un numero y un signo"}></PatternRule>
                                            <RequiredRule message="El password es requerido" />                                            
                                        </SimpleItem>                                        
                                        <ButtonItem colSpan={1}>
                                            <ButtonOptions icon={iconoBotonMostrarPassword} onClick={mostrarPassword} />
                                        </ButtonItem> 
                                    </GroupItem>
                                    }            
                                </GroupItem>                               
                            </GroupItem>    
                            <GroupItem colCount={12}>
                                <EmptyItem colSpan={3}/>    
                                    <ButtonItem>
                                        <ButtonOptions disabled={idNumero === 0 || loadingRemove }  onClick={cambiarPassword} visible={userCon.id === usuario.id || (userCon.rolId === 1 && usuario.rolId !== 1) }>  {/* || (userCon.rolId === 1 && usuario.rolId !== 1) */}
                                            <span className="dx-button-text">                                            
                                                Cambiar Contraseña
                                            </span>
                                        </ButtonOptions>
                                    </ButtonItem>
                            </GroupItem> 
                            <GroupItem colCount={12}>
                                <EmptyItem colSpan={10} />
                                <ButtonItem>
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPost || loadingPut)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>                                
                                <ButtonItem>
                                    <ButtonOptions icon={iconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove }/>
                                </ButtonItem> 
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            )}            
        </div >
    );
}