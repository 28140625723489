import { useEffect, useRef, useCallback, useMemo, FC } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
//import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';
import { useParams } from 'react-router';

interface Props {
  children: any
  selectedItemChanged: any
  openMenu: any
  compactMode: any
  onMenuReady: any
}

interface ParamType {
  currentPath: string
}

export const SideNavigationMenu: FC<Props> = ({ children, selectedItemChanged, openMenu, compactMode, onMenuReady }) => {

  const { isLarge } = useScreenSize();
  function normalizePath () {    
    return navigation.map((item) => {
      // if(item.path && !(/^\//.test(item.path))){ 
      //   item.path = `/${item.path}`;
      // }
      return {...item, expanded: isLarge}; 
    });
  }
    
  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //const { navigationData: { currentPath } } = useNavigation();
  const treeViewRef = useRef<TreeView>(null);
  const currentPath = useParams<ParamType>();
  
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e: any) => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode, treeViewRef]);


  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}