export interface ResetPasswordRequest {
    //idUsuario: string,
    token: string,
    newPassword: string,
    confirmPassword: string    
}
  
export const newResetPasswordRequest: ResetPasswordRequest = {
    //idUsuario:'',
    token: '',
    newPassword: '',
    confirmPassword: ''
}