import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './app-routes';
import { Footer } from './components';
import { SideNavOuterToolbar as SideNavBarLayout } from './components/layouts/side-nav-outer-toolbar/side-nav-outer-toolbar';

export const Content: FC = () => {

  return (
    <SideNavBarLayout >
      <Switch>
        {routes.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        <Redirect to={'/dashboard/finalform'} />
      </Switch>
      <Footer>
        Centro Tecnologico del Calzado de la Rioja
      </Footer>
    </SideNavBarLayout>
  );
}
