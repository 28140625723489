import { ErrorType } from '../types/error';

enum HttpRequestType {
    GET     = 'get',
    POST    = 'post',
    DELETE  = 'delete',
    PUT     = 'put',
}

function getHeaders(tipoLlamada: string) {
    const headers = [] as string[][];
    headers.push(['token', `${localStorage.getItem("token")}` || '']); // TODO: Borralo de aqui, usas beearer
    headers.push(['Authorization', "Bearer " + localStorage.getItem("token") || '']);
    switch (tipoLlamada) {
        case HttpRequestType.POST:
        case HttpRequestType.PUT:
            headers.push(['Content-Type', 'application/json']);
            return headers;
        case HttpRequestType.GET:
        case HttpRequestType.DELETE:
        default:
            return headers;
    }
}

function http<T>(request: RequestInfo): Promise<T> {
    return fetch(request)
        .then(res => {
            if (res.ok) {
                return res.json() as Promise<T>;
            }
            return (res.json() as Promise<ErrorType>)
                .then(data => {
                    return Promise.reject(data);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        })
        .catch(err => {
            if (err?.code) {
                return Promise.reject(err);
            }
            const error: ErrorType = { code: 999, message: 'No hay conexión con el servidor. ' + err.message };
            return Promise.reject(error);
        });
}


export function get<T>(
    path: string,
    signal: AbortSignal,
    args: RequestInit = { method: HttpRequestType.GET, signal:signal, headers: getHeaders(HttpRequestType.GET) } //, headers: header, mode:'cors' }
): Promise<T> {
    return http<T>(new Request(path, args));
};

export function post<T, J>(
    path: string,
    body: J,
    signal: AbortSignal,
    args: RequestInit = { method: HttpRequestType.POST, body: JSON.stringify(body), signal: signal, headers: getHeaders(HttpRequestType.POST) }
): Promise<T> {
    return http<T>(new Request(path, args));
};

export function put<T, J>(
    path: string,    
    body: J,
    signal: AbortSignal,
    args: RequestInit = { method: HttpRequestType.PUT, body: JSON.stringify(body), signal: signal, headers: getHeaders(HttpRequestType.PUT) }
): Promise<T> {
    return http<T>(new Request(path, args));
};

export function remove<T>(
    path: string,
    signal: AbortSignal,
    args: RequestInit = { method: HttpRequestType.DELETE, signal: signal, headers: getHeaders(HttpRequestType.DELETE) }
): Promise<T> {
    return http<T>(new Request(path, args));
};


/*

/// Otros ejemplos con react

useEffect(() => {
    let jurjur :string |null= '';
    const JA = async () => {
      const loginRequest = {email: 'jperez@ctcr.es', password: '1'} as LoginRequest;
      return await TryToLogIn( 'https://192.168.1.168:5001/api/usuarios/authenticate', loginRequest );

      //return await get<EmpresaType[]>( 'https://192.168.1.168:5001/api/empresas');
    }
    JA()
      .then(x => console.log(x.data?.token ?? null) )
      .catch(err => console.log("ya estamos otra vez con la misma mierda"))
      ;

  });

  */

