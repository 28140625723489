import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoPermisos from '../imgIconosTarjetas/iconoPermisos.png';
import { PermisoToRolType, PermisoType, UsuarioType } from '../../../types';
import { StoreContext } from '../../../contexts/context';
import { GetPermisosToRoles } from '../../../services/permisosToRoles/permisosToRoles-service';
import { GetUsuarios } from '../../../services/users/users-service';

interface Props {
    permiso: PermisoType
    isRelacion: boolean
}

export const TarjetaPermiso: FC<Props> = ({ permiso, isRelacion }) => {

    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;

    let clasescss = "estilo fondodesactivado";
    
    const { data: permisosToRoles } = GetPermisosToRoles();
    const { data: usuarios } = GetUsuarios();
    
    if (isRelacion !== true) {
        //Seleccionado permiso    
        if (hovered.permiso === permiso.id) {
            clasescss = "estilo fondoactivado";
        }

        //Seleccionado rol
        if (permisosToRoles !== null)
        {
            const permisoToRol = (permisosToRoles as PermisoToRolType[]).find(p2r => p2r.permisoId === permiso.id && p2r.rolId === hovered.rol);
            if (permisoToRol) {
                if (hovered.rol === permisoToRol.rolId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado usuario
        if (usuarios !== null)
        {
            const usuario = (usuarios as UsuarioType[]).find(r => r.id === hovered.user);
            if (usuario) {
                const permisosToRol = (permisosToRoles as PermisoToRolType[]).filter(p2r => p2r.rolId === usuario.rolId);
                const permisoToRolActivo = permisosToRol.find(ptr => ptr.permisoId === permiso.id);
                if (permisoToRolActivo) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }
    }

    return (        
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarPermiso', payload: permiso.id})} onMouseLeave={() => dispatchHovered({type:'actualizarPermiso', payload: 0}) } >
            <CardHeader className="headerFont"> {permiso.nombre} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoPermisos} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px` }}>
                        <CardText className="divididoLineaDescripcion">{permiso.descripcion}</CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );

}