// Clase propia para construir los metodos para las llamadas al ws
import { rolesUrl } from '../../utils/rutasllamadasapi';
import { RolType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { RolRequest } from '../../types/requests/rolRequest';

export const GetRoles = () => {
    return useFetch<RolType[]>(rolesUrl);
}

export const GetRol = (id: number) => {
    return useFetch<RolType>(rolesUrl + id);
}

export const AddRol = (rol: RolRequest) => {
    return usePostHook<RolType, RolRequest>(rolesUrl, rol);
}

export const UpdateRol = (id: number, rol: RolRequest) => {
    return usePutHook<RolType, RolRequest>(rolesUrl + id, rol);
}

export const RemoveRol = (id: number) => {
    return useDeleteHook<number>(rolesUrl + id);
}