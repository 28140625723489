import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoRoles from '../imgIconosTarjetas/iconoRoles.png';
import { PermisoToRolType, RolType, UsuarioType } from '../../../types';
import { StoreContext } from '../../../contexts/context';
import { GetUsuarios } from '../../../services/users/users-service';
import { GetPermisosToRoles } from '../../../services/permisosToRoles/permisosToRoles-service';

interface Props {
    rol: RolType
    isRelacion: boolean
}

export const TarjetaRol: FC<Props> = ({ rol, isRelacion }) => {

    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;
   
    let clasescss = "estilo fondodesactivado";

    const { data: usuarios } = GetUsuarios();
    const { data: permisosToRoles } = GetPermisosToRoles();
 
    if (isRelacion !== true) {
        //Seleccionado rol    
        if (hovered.rol === rol.id) {
            clasescss = "estilo fondoactivado";
        }

        //Seleccionado usuario
        if (usuarios !== null)
        {
            const usuarioActivo = (usuarios as UsuarioType[]).find(u => u.rolId === rol.id && u.id === hovered.user);
            if (usuarioActivo) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado permiso
        if (permisosToRoles !== null)
        {
            const permisoToRol = (permisosToRoles as PermisoToRolType[]).find(p2r => p2r.rolId === rol.id && p2r.permisoId === hovered.permiso);
            if (permisoToRol) {
                if (hovered.permiso === permisoToRol.permisoId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }
    }    

    return (      
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarRol', payload:rol.id})}
        onMouseLeave={() => dispatchHovered({type:'actualizarRol', payload:0})} >
            <CardHeader className="headerFont"> {rol.nombre} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoRoles} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px` }}>
                        <CardText className="divididoLineaDescripcion">{rol.descripcion}</CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );

}