import { useEffect, useState } from "react";
import { ErrorType } from '../types';
import { remove } from '../services/httpService';

export function useDeleteHook<T>(url: string) {
    const [data, setData] = useState<T | null>(null);
    const [loading, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | null>(null);

    const [execute, setExecute] = useState<Boolean>(false);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        if (execute) {
            setIsPending(true);
            setError(null);
            remove<T>(url, signal)
                .then(data => {
                    setIsPending(false);
                    setError(null);
                    setData(data);
                    setExecute(false);
                })
                .catch((err: ErrorType) => {
                    console.log(err);
                    setIsPending(false);
                    setError(err);
                    setExecute(false);
                });
        }     
        
        return ()=> abortController.abort();

    }, [execute, url]);

    const sendRemoveSubmit = () => {
        setExecute(true);
    };

    return {data, loading, error, sendRemoveSubmit};
}