import { FC, ReactNode } from 'react';
import './tarjetaBase.scss';

interface Props {
    children: ReactNode
}

export const Tarjeta: FC<Props> = ({ children }) => {      

    return (
        <div className="cuadrado">
            {children}
        </div>
    );
};