export interface ChangePasswordSuperRequest {      
    idUsuario: number,
    newPassword: string,
    confirmPassword: string    
}
  
export const newChangePasswordSuperRequest: ChangePasswordSuperRequest = {
    idUsuario: 0,
    newPassword: '',
    confirmPassword: ''
}