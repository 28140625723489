import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoNodos from '../imgIconosTarjetas/iconoNodos.png';
import { AntenaType, GpiType, GpoType, NodoToLectorType, NodoType, ZonaToNodoType } from '../../../types';
import { StoreContext } from '../../../contexts/context';
import { GetZonasToNodos } from '../../../services/zonasToNodos/zonasToNodos-service';
import { GetNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import { GetAntenas } from '../../../services/antenas/antenas-service';
import { GetGpis } from '../../../services/gpis/gpis-service';
import { GetGpos } from '../../../services/gpos/gpos-service';

interface Props {
    nodo: NodoType,
    isRelacion: boolean
}

export const TarjetaNodo: FC<Props> = ({ nodo, isRelacion }) => {
    
    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;
  
    //Seleccionado nodo
    const { data: zonasToNodos } = GetZonasToNodos();
    const { data: nodosToLectores } = GetNodosToLectores();
    const { data: antenas } = GetAntenas();
    const { data: gpis } = GetGpis();
    const { data: gpos } = GetGpos();

    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {

        //Seleccionado nodo
        if (hovered.nodo === nodo.id) {
            clasescss = "estilo fondoactivado";
        }

        //Seleccionado zona   
        if (zonasToNodos !== null)
        {
            const zonaToNodo = (zonasToNodos as ZonaToNodoType[]).find(z2n => z2n.nodoId === nodo.id && z2n.zonaId === hovered.zona);
            if (zonaToNodo) {
                if (hovered.zona === zonaToNodo.zonaId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado lector   
        if (nodosToLectores !== null)
        {
            const nodoToLector = (nodosToLectores as NodoToLectorType[]).find(n2l => n2l.nodoId === nodo.id && n2l.lectorId === hovered.lector);
            if (nodoToLector) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado antena   
        if (antenas !== null)
        {
            const antenaActiva = (antenas as AntenaType[]).find(a => a.nodoId === nodo.id && a.id === hovered.antena)
            if (antenaActiva) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado Gpi   
        if (gpis) {
            const gpiSeleccionado = (gpis as GpiType[]).find(g => g.id === hovered.gpi);
            if (gpiSeleccionado)
            {
                if ((gpiSeleccionado as GpiType).nodoId === nodo.id) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado Gpo
        if (gpos) {
            const gpoSeleccionado = (gpos as GpoType[]).find(g => g.id === hovered.gpo);
            if (gpoSeleccionado)
            {               
                if ((gpoSeleccionado as GpoType).nodoId === nodo.id) {
                    clasescss = "estilo fondoactivado";
                }               
            }
        }
    }

    return (
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarNodo', payload: nodo.id})} onMouseLeave={() => dispatchHovered({type:'actualizarNodo', payload: 0}) }>
            <CardHeader className="headerFont"> {nodo.nombre} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoNodos} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px` }}>
                        <CardText className="divididoLineaDescripcion">{nodo.descripcion}</CardText>
                    </div>
                </div>               
            </CardBody>
        </Card>
    );
};