import { FC, useEffect, useState } from 'react';
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    NumericRule
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './datosAntena.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useParams, useHistory } from "react-router-dom";
import iconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import { GetLectores } from '../../services/lectores/lectores-service';
import { GetNodos } from '../../services/nodos/nodos-service';
import { AddAntena, GetAntena, RemoveAntena, UpdateAntena } from '../../services/antenas/antenas-service';
import { LectorType, NodoType } from '../../types';
import { AntenaRequest, newAntenaRequest } from '../../types/requests/antenaRequest';
import notify from 'devextreme/ui/notify';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import { confirm } from 'devextreme/ui/dialog';
import { Slider } from 'devextreme-react/slider';
import { NumberBox } from 'devextreme-react/number-box';

interface Params {
    id: string
}

export const DatosAntena: FC = () => {
    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;
    const { data: antena, isPending, error } = GetAntena(idNumero);
    const { data: lectores, isPending: loadingLectores, error: errLectores } = GetLectores();

    const [minSliderValuePotencia, setMinSliderValuePotencia] = useState(0);
    const [maxSliderValuePotencia, setMaxSliderValuePotencia] = useState(0);
    const [stepSliderValuePotencia, setStepSliderValuePotencia] = useState(0);
    const [sliderValuePotencia, setSliderValuePotencia] = useState(antena?.potencia);

    const [minSliderValueSensi, setMinSliderValueSensi] = useState(0);
    const [maxSliderValueSensi, setMaxSliderValueSensi] = useState(0);
    const [stepSliderValueSensi, setStepSliderValueSensi] = useState(0);
    const [sliderValueSensi, setSliderValueSensi] = useState(antena?.sensibilidad);

    useEffect(() => {
        if (antena)
        {
            setSliderValuePotencia(antena.potencia);
            setSliderValueSensi(antena.sensibilidad);
            // Aqui poner los valores posibles de potencia y sensibilidad por tipo de lector
            var lector = lectores?.find(a => a.id === antena?.lectorId);
            if (lector)
            {
                switch(lector.tipo)
                {
                    case 100: // Impinj
                        // Potencia
                        setMinSliderValuePotencia(100);
                        setMaxSliderValuePotencia(315);
                        setStepSliderValuePotencia(5);
                        // Sensibilidad
                        setMinSliderValueSensi(-70);
                        setMaxSliderValueSensi(-30);
                        setStepSliderValueSensi(1);
                    break;
                    case 200: // Zebra
                        // Potencia
                        setMinSliderValuePotencia(0);
                        setMaxSliderValuePotencia(192);
                        setStepSliderValuePotencia(1);
                        // Sensibilidad
                        setMinSliderValueSensi(1);
                        setMaxSliderValueSensi(1);
                        setStepSliderValueSensi(1);
                    break;
                    default:
                      break;
                }    
            }        
        }
    }, [antena, lectores]);           
    
  
    const [lectoresOptions, setLectoresOptions] = useState({});
    useEffect(() => {
        let dataLectores: LectorType[] = [];
        loadingLectores && (dataLectores = []);
        errLectores && (dataLectores = []);
        lectores && (dataLectores = lectores);
        setLectoresOptions({
            dataSource: dataLectores,
            valueExpr: "id",
            displayExpr: "nombre",
            disabled: false,
            placeholder: loadingLectores
                ? "Loading..."
                : errLectores
                    ? "¡Error!"
                    : ""
        });
    }, [lectores, loadingLectores, errLectores]);

    const { data: nodos, isPending: loadingNodos, error: errNodos } = GetNodos();
    const [nodosOptions, setNodosOptions] = useState({});
    useEffect(() => {
        let dataNodos: NodoType[] = [];
        loadingNodos && (dataNodos = []);
        errNodos && (dataNodos = []);
        nodos && (dataNodos = nodos);
        setNodosOptions({
            dataSource: dataNodos,
            valueExpr: "id",
            displayExpr: "nombre",
            disabled: false,
            placeholder: loadingNodos
                ? "Loading..."
                : errNodos
                    ? "¡Error!"
                    : ""
        });
    }, [nodos, loadingNodos, errNodos]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        const antenaNueva: AntenaRequest = {
            puerto: Number(datos["puerto"]),
            potencia: Number(sliderValuePotencia),
            sensibilidad: Number(sliderValueSensi),
            lectorId: Number(datos["lectorId"]),
            nodoId: Number(datos["nodoId"]),
            activo: Boolean(datos["activo"])
        }

        if (idNumero !== 0) {
            sendPutSubmit(antenaNueva);
        }
        else {
            sendPostSubmit(antenaNueva);
        }
    }

    const { data: updateAntena, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdateAntena(idNumero, newAntenaRequest);
    const { data: addAntena, loading: loadingPost, error: errorPost, sendPostSubmit } = AddAntena(newAntenaRequest);
    const { data: removeAntena, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveAntena(idNumero);

    useEffect(() => {
        if (updateAntena)
            //history.push('/datosGenerales');
            history.goBack();
    }, [updateAntena, history]);

    useEffect(() => {
        if (addAntena)
            history.push('/datosGenerales');
    }, [addAntena, history]);

    useEffect(() => {
        if (removeAntena)
            history.push('/datosGenerales');
    }, [removeAntena, history]);
    
    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])

    function eliminar() {
        let result = confirm("<b>Se va a borrar la antena, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
        });       
    }  

    function handleSliderValuePotencia(value: any ) {
        setSliderValuePotencia(value.value);
    }

    function handleSliderValueSensi(value: any ) {
        setSliderValueSensi(value.value);
    }

    return (
        <div>
            {isPending && <Loading />}
            {error && <Error error={error} />}
            {antena && (
                <div>
                    <h2 className={'content-block'}>Antena</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={antena}>
                            <GroupItem colCount={4}>
                                <SimpleItem render={logoAntena}>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField="puerto" editorType="dxNumberBox" editorOptions={{max:999999}}>
                                        <NumericRule />
                                        <RequiredRule message="El puerto es requerido" />
                                    </SimpleItem>
                                    <SimpleItem dataField="potencia">                                                                          
                                        <Slider min={minSliderValuePotencia}
                                                max={maxSliderValuePotencia}
                                                value={sliderValuePotencia}
                                                step={stepSliderValuePotencia}
                                                onValueChanged={handleSliderValuePotencia} />                                
                                        <NumberBox  min={minSliderValuePotencia}
                                                    max={maxSliderValuePotencia}
                                                    value={sliderValuePotencia}
                                                    step={stepSliderValuePotencia}
                                                    showSpinButtons={true}                                                    
                                                    onValueChanged={handleSliderValuePotencia} />                                       
                                    </SimpleItem> 
                                    <SimpleItem dataField="sensibilidad" >
                                        <Slider min={minSliderValueSensi}
                                                max={maxSliderValueSensi}
                                                value={sliderValueSensi}
                                                step={stepSliderValueSensi}
                                                onValueChanged={handleSliderValueSensi} />                                    
                                        <NumberBox  min={minSliderValueSensi}
                                                    max={maxSliderValueSensi}
                                                    value={sliderValueSensi}
                                                    step={stepSliderValueSensi}
                                                    showSpinButtons={true}
                                                    onValueChanged={handleSliderValueSensi} /> 
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="lectorId"
                                        editorType="dxSelectBox"
                                        editorOptions={lectoresOptions} >
                                        <RequiredRule message="El lector es requerido" />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="nodoId"
                                        editorType="dxSelectBox"
                                        editorOptions={nodosOptions} >
                                        <RequiredRule message="El nodo es requerido" />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem colCount={12}>
                                <EmptyItem colSpan={10} />
                                <ButtonItem>
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPost || loadingPut)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>                                
                                <ButtonItem>
                                    <ButtonOptions icon={iconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove }/>
                                </ButtonItem>                               
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            )}           
        </div>
    );
}

function logoAntena() {
    return (
        <div className="form-logo-antena"></div>
    );
}
