// Clase propia para construir los metodos para las llamadas al ws
import { usuariosUrl, authenticateUrl, authorizeUrl, usuarioTokenUrl, cambiarEmpresaUsuarioUrl, forgotPasswordUrl, resetPasswordUrl, changePasswordUrl, changePasswordSuperUrl } from '../../utils/rutasllamadasapi';
import { UsuarioType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { UsuarioRequest } from '../../types/requests/usuarioRequest';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';


export function Login<LoginResponse, LoginRequest>(loginRequest: LoginRequest) { 
    return usePostHook<LoginResponse, LoginRequest>(authenticateUrl, loginRequest);    
}

// export const Logout = () => {
//     localStorage.removeItem('token');
//     localStorage.clear();
// }

export const Authorize = () => {
    return useFetch<boolean>(authorizeUrl);
}

export const GetUserToken = () => {
    return useFetch<UsuarioType>(usuarioTokenUrl);
}

export const GetUsuarios = () => {
    return useFetch<UsuarioType[]>(usuariosUrl);
}

export const GetUsuario = (id: number) => {
    return useFetch<UsuarioType>(usuariosUrl + id);
}

export const AddUser = (usuario: UsuarioRequest) => {
    return usePostHook<UsuarioType, UsuarioRequest>(usuariosUrl, usuario);
}

export const UpdateUser = (id: number, usuario: UsuarioRequest) => {
    return usePutHook<UsuarioType, UsuarioRequest>(usuariosUrl + id, usuario);
}

export const RemoveUser = (id: number) => {
    return useDeleteHook<number>(usuariosUrl + id);
}

export const CambiarEmpresaUsuario = (id: number, usuario: UsuarioRequest) => {
    return usePutHook<UsuarioType, UsuarioRequest>(cambiarEmpresaUsuarioUrl + id, usuario);
}

export function ForgotPassword<ForgotPasswordResponse, ForgotPasswordRequest>(forgotPassword: ForgotPasswordRequest) {
    return usePostHook<ForgotPasswordResponse, ForgotPasswordRequest>(forgotPasswordUrl, forgotPassword);
}

export function ResetPassword<ResetPasswordResponse, ResetPasswordRequest>(resetPassword: ResetPasswordRequest){
    return usePostHook<ResetPasswordResponse, ResetPasswordRequest>(resetPasswordUrl, resetPassword);
}

export function ChangePassword<ChangePasswordResponse, ChangePasswordRequest>(changePassword: ChangePasswordRequest){
    return usePostHook<ChangePasswordResponse, ChangePasswordRequest>(changePasswordUrl, changePassword);
}

export function ChangePasswordSuper<ChangePasswordResponse, ChangePasswordSuperRequest>(changePassword: ChangePasswordSuperRequest){
    return usePostHook<ChangePasswordResponse, ChangePasswordSuperRequest>(changePasswordSuperUrl, changePassword);
}