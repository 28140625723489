// Clase propia para construir los metodos para las llamadas al ws
import { permisosToRolesUrl } from '../../utils/rutasllamadasapi';
import { PermisoToRolType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { PermisoToRolRequest } from '../../types/requests/permisoToRolRequest';

export const GetPermisosToRoles = () => {
    return useFetch<PermisoToRolType[]>(permisosToRolesUrl);
}

export const AddPermisoToRol = (permisoToRol: PermisoToRolRequest) => {
    return usePostHook<PermisoToRolType, PermisoToRolRequest>(permisosToRolesUrl, permisoToRol);
}

export const UpdatePermisoToRol = (id: number, permisoToRol: PermisoToRolRequest) => {
    return usePutHook<PermisoToRolType, PermisoToRolRequest>(permisosToRolesUrl + id, permisoToRol);
}

export const RemovePermisoToRol = (id: number) => {
    return useDeleteHook<number>(permisosToRolesUrl + id);
}