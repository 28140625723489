//import { Dashboard } from './pages/dashboard/dashboard';
import { DashboardDev } from './pages/dashboard/dashboardDev';
import { DatosUsuario } from './pages/perfil/datosUsuario';
import { DatosGenerales } from './pages/datos-generales/datos-generales';
import { DatosUsuarios } from './pages/usuarios/datosUsuarios';
import { DatosNodo } from './pages/nodos/datosNodo';
import { DatosPermiso } from './pages/permisos/datosPermiso';
import { DatosRol } from './pages/roles/datosRol';
import { DatosAntena } from './pages/antenas/datosAntena';
import { DatosGpi } from './pages/gpis/datosGpi';
import { DatosGpo } from './pages/gpos/datosGpo';
import { DatosZona } from './pages/zonas/datosZona';
import { DatosLector } from './pages/lectores/datosLector';
import { ChangePasswordFC } from './components/change-password/change-password';
import { ChangePasswordSuperFC } from './components/change-password/change-password-super';

const routes = [  
    {
        path: '/usuario',
        component: DatosUsuario
    },
    {
        path: '/usuario/:id',
        component: DatosUsuario
    },
    {
        path: '/dashboard/:id',
        component: DashboardDev
    },  
    {
        path: '/datosGenerales',
        component: DatosGenerales
    },
    {
        path: '/datosUsuarios',
        component: DatosUsuarios
    },
    {
        path: '/nodo',
        component: DatosNodo
    },
    {
        path: '/nodo/:id',
        component: DatosNodo
    },
    {
        path: '/zona',
        component: DatosZona
    },
    {
        path: '/zona/:id',
        component: DatosZona
    },
    {
        path: '/lector',
        component: DatosLector
    },
    {
        path: '/lector/:id',
        component: DatosLector
    },
    {
        path:  '/antena',
        component: DatosAntena
    },
    {
        path: '/antena/:id',
        component: DatosAntena
    },
    {
        path: '/gpi',
        component: DatosGpi
    },
    {
        path: '/gpi/:id',
        component: DatosGpi
    },
    {
        path: '/gpo',
        component: DatosGpo
    },
    {
        path: '/gpo/:id',
        component: DatosGpo
    },
    {
        path: '/roles',
        component: DatosRol
    },
    {
        path: '/roles/:id',
        component: DatosRol
    },
    {
        path: '/permisos',
        component: DatosPermiso
    },
    {
        path: '/permisos/:id',
        component: DatosPermiso
    },
    {
        path: '/changePassword',
        component: ChangePasswordFC
    },
    {
        path: '/changePasswordSuper/:idUsuario',
        component: ChangePasswordSuperFC
    }
];

export default routes.map(route => {
  return {
    ...route,
    component: route.component
  };
});