// Clase propia para construir los metodos para las llamadas al ws
import { zonasToNodosUrl } from '../../utils/rutasllamadasapi';
import { ZonaToNodoType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { ZonaToNodoRequest } from '../../types/requests/zonaToNodoRequest';

export const GetZonasToNodos = () => {
    return useFetch<ZonaToNodoType[]>(zonasToNodosUrl);
}

export const AddZonaToNodo = (zonaToNodo: ZonaToNodoRequest) => {
    return usePostHook<ZonaToNodoType, ZonaToNodoRequest>(zonasToNodosUrl, zonaToNodo);
}

export const UpdateZonaToNodo = (id:number, zonaToNodo: ZonaToNodoRequest) => {
    return usePutHook<ZonaToNodoType, ZonaToNodoRequest>(zonasToNodosUrl + id, zonaToNodo);
}

export const RemoveZonaToNodo = (id: number) => {
    return useDeleteHook<number>(zonasToNodosUrl + id);
}