import { FC, useContext, useEffect, useState } from "react";
//import { Error } from "./components/loading/error";
import { Loading } from "./components/loading/loading";
import { StoreContext } from "./contexts/context";
import { GetEmpresa } from "./services/empresas/empresas-service";

export const AppInfo: FC = () => {

    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const { useUsuarioSlice } = useContext(StoreContext);
    const [usuario,] = useUsuarioSlice;
        
    const {data, isPending} = GetEmpresa(usuario.empresaId);    
    useEffect (()=> {
        if (data)
        {
            setNombreEmpresa(data.nombre);
        }
    }, [data]);   
    
    return ( 
        <>
        {isPending && <Loading />}  
        {/* {error && <Error error={error} />}     */}
        {data &&    
            <div className="myTitle">    
                {nombreEmpresa}        
            </div>
        }
        </>
    );
}

// export const AppInfo = {
//     title: 'RFID SAAS',
//     nombreEmpresa: obtenerEmpresa() 
// };