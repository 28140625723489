//export const baseUrl = 'https://192.168.1.168:5001'
//export const baseUrl = 'https://81.9.131.166:5001'
export const baseUrl = 'https://biraid.ctcr.es'

export const nodosUrl = baseUrl + '/api/nodos/';
export const antenasUrl = baseUrl + '/api/antenas/';
export const lectoresUrl = baseUrl + '/api/lectores/';
export const zonasUrl = baseUrl + '/api/zonas/';
export const nodosToLectoresUrl = baseUrl + '/api/nodosToLectores/';
export const zonasToNodosUrl = baseUrl + '/api/zonasToNodos/';
export const gpisUrl = baseUrl + '/api/gpis/';
export const gposUrl = baseUrl + '/api/gpos/';
export const usuariosUrl = baseUrl + '/api/usuarios/';
export const authenticateUrl = baseUrl + '/api/usuarios/authenticate/';
export const authorizeUrl = baseUrl + '/api/usuarios/authorize/';
export const usuarioTokenUrl = baseUrl + '/api/usuario';
export const cambiarEmpresaUsuarioUrl = baseUrl + '/api/usuarios/empresa/';
export const rolesUrl = baseUrl + '/api/roles/';
export const permisosUrl = baseUrl + '/api/permisos/';
export const permisosToRolesUrl = baseUrl + '/api/permisosToRoles/';
export const empresasUrl = baseUrl + '/api/empresas/';
export const graficaCalendarUrl = baseUrl + '/api/graficaCalendar/';
export const graficaSankeyUrl = baseUrl + '/api/graficaSankey/';
export const graficaBarrasUrl = baseUrl + '/api/graficaBarras/';
export const graficaLinealNodosUrl = baseUrl + '/api/graficaLinealNodos/';
export const graficaLinealZonasUrl = baseUrl + '/api/graficaLinealZonas/';
export const graficaLinealLectoresUrl = baseUrl + '/api/graficaLinealLectores/';
export const graficaCircularUrl = baseUrl + '/api/graficaCircular/';
export const dashboardUrl = baseUrl + '/api/dashboard';
export const forgotPasswordUrl = baseUrl + '/api/usuarios/fogotPassword';
export const resetPasswordUrl = baseUrl + '/api/usuarios/resetPassword';
export const changePasswordUrl = baseUrl + '/api/usuarios/changePassword';
export const changePasswordSuperUrl = baseUrl + '/api/usuarios/changePasswordSuper';