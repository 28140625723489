import { FC, ReactChild, useEffect, useState } from 'react'
import './carousel.css'

interface Props {
    children: ReactChild[]
}

export const Carousel: FC<Props> = ({children}) => {
   
    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(0);
   
    const [touchPosition, setTouchPosition] = useState(null);

    // Set the length to match current children from props
    useEffect(() => {
        if (children !== null)
        {
            setLength(children?.length)
        }
    }, [children])

    const next = (e: any) => {
        e.preventDefault();
        let s = 0;       
        if (length != null)
        {
            s = length - 6;
        }
        if (currentIndex < s) {
            setCurrentIndex(prevState => prevState + 1)
        }       
    }

    const prev = (e: any) => {
        e.preventDefault();
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const handleTouchStart = (e: any) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e: any) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next(e)
        }

        if (diff < -5) {
            prev(e)
        }

        setTouchPosition(null)
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                {
                    currentIndex > 0 &&
                    <button onClick={prev} className="left-arrow">
                        &lt;
                    </button>
                }
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${6}`}
                        style={{ transform: `translateX(-${currentIndex * (100 / 6)}%)` }}
                    >
                        {children}
                    </div>
                </div>
                {/* You can alwas change the content of the button to other things */}
                {                    
                    currentIndex < (length - 6) &&
                    <button onClick={next} className="right-arrow">
                        &gt;
                    </button>
                }
            </div>
        </div>
    )
}