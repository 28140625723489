import { FC } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { SuperAdminEmpresaSelector} from '../../elements/super-admin-empresa-selector/super-admin-empresa-selector';
import { AppInfo } from '../../app-info';

interface Props {
  menuToggleEnabled: boolean, 
  toggleMenu: any
}

export const Header:FC<Props> = ({ menuToggleEnabled, toggleMenu }) => (  
  <div className= {'layout-header'} >
    <header className={'header-component'}>   
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
        > 
            <AppInfo />
        </Item>
        <Item                
            location={'after'}              
        >
            <SuperAdminEmpresaSelector  />
        </Item>
        <Item 
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  </div>  
);