// Clase propia para construir los metodos para las llamadas al ws
import { lectoresUrl } from '../../utils/rutasllamadasapi';
import { LectorType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { LectorRequest } from '../../types/requests/lectorRequest';

export const GetLectores = () => {
    return useFetch<LectorType[]>(lectoresUrl);
}

export const GetLector = (id: number) => {
    return useFetch<LectorType>(lectoresUrl + id);
}

export const AddLector = (lector: LectorRequest) => {
    return usePostHook<LectorType, LectorRequest>(lectoresUrl, lector);
}

export const UpdateLector = (id: number, lector: LectorRequest) => {
    return usePutHook<LectorType, LectorRequest>(lectoresUrl + id, lector);
}

export const RemoveLector = (id: number) => {
    return useDeleteHook<number>(lectoresUrl + id);
}