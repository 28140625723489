import { HoveredType } from "../../types/hovered";
import { ActionHovered } from "../actions/action-types";
import update from 'immutability-helper';

export const hoveredReducer = (state: HoveredType, action: ActionHovered): HoveredType => {
    switch (action.type) {
        case 'actualizarNodo':
            return update(state, { nodo: { $set: action.payload } });
        case 'actualizarLector':
            return update(state, { lector: { $set: action.payload } });
        case 'actualizarZona':
            return update(state, { zona: { $set: action.payload } });
        case 'actualizarAntena':
            return update(state, { antena: { $set: action.payload } });
        case 'actualizarGpi':
            return update(state, { gpi: { $set: action.payload } });
        case 'actualizarGpo':
            return update(state, { gpo: { $set: action.payload } });
        case 'actualizarUser':
            return update(state, { user: { $set: action.payload } });
        case 'actualizarRol':
            return update(state, { rol: { $set: action.payload } });
        case 'actualizarPermiso':
            return update(state, { permiso: { $set: action.payload } });
        default:
            return state;
    }
}