import { FC, useEffect, useState } from "react";
import { CardContainer, CheckBoxZonaXNodo, CustomTitle, Tarjeta, TarjetaNodo } from "../../components";
import { GetNodos } from "../../services/nodos/nodos-service";
import { GetZonasToNodos } from "../../services/zonasToNodos/zonasToNodos-service";
import { NodoType } from "../../types";
import Accordion from 'devextreme-react/accordion';
import { Loading } from "../../components/loading/loading";
import { Error } from "../../components/loading/error";

interface Props {
    idZona: number
}

export const AcordeonNodosZona: FC<Props> = ({idZona}) => {
    const [acordeon, setAcordeon] = useState([{}]);
    
    const { data: nodos, isPending: loadingNodos, error: errNodos } = GetNodos();
    const { data: zonasToNodos, isPending: loadingZonasToNodos, error: errorZonasToNodos } = GetZonasToNodos();

    useEffect(() => {
        let tarjetasNodosOrigen = null;
        let tarjetasNodosDestino = null;
        if (nodos !== null) {
            const renderlistadoNodosOrigen = (nodo: NodoType) => {
                let resultado = null;
                let zonasToNodosZona = zonasToNodos?.find(x => x.zonaId === idZona && x.nodoId === nodo.id && x.esOrigen === true);
                resultado = <Tarjeta key={nodo.id}>
                                <TarjetaNodo nodo={nodo} isRelacion={true} />
                                <CheckBoxZonaXNodo zonaToNodoId={zonasToNodosZona?.id || 0}
                                                        zonaId={idZona} 
                                                        nodoId={nodo.id}
                                                        esOrigen={true} />
                            </Tarjeta>
                return resultado;
            };
            tarjetasNodosOrigen = nodos.map(zona => renderlistadoNodosOrigen(zona));

            const renderlistadoNodosDestino = (nodo: NodoType) => {
                let resultado = null;
                let zonasToNodosZona = zonasToNodos?.find(x => x.zonaId === idZona && x.nodoId === nodo.id && x.esOrigen === false);
                resultado = <Tarjeta key={nodo.id}>
                                <TarjetaNodo nodo={nodo} isRelacion={true} />
                                <CheckBoxZonaXNodo zonaToNodoId={zonasToNodosZona?.id || 0}
                                                    zonaId={idZona} 
                                                    nodoId={nodo.id} 
                                                    esOrigen={false}/>
                            </Tarjeta>
                return resultado;
            };
            tarjetasNodosDestino = (nodos as NodoType[]).map(zona => renderlistadoNodosDestino(zona));
        }
        const componentes = [{
            'Title': 'Nodos de los que es Origen',
            'children': tarjetasNodosOrigen
        }, {
            'Title': 'Nodos de los que es Destino',
            'children': tarjetasNodosDestino
        }];
        setAcordeon(componentes);
    }, [nodos, idZona, zonasToNodos])

    return (
        <div>
            {(loadingNodos || loadingZonasToNodos) && <Loading />}
            {errNodos && <Error error={errNodos} />}
            {errorZonasToNodos && <Error error={errorZonasToNodos} />}

            {(nodos && zonasToNodos) &&
                <div id="accordion">
                    <Accordion
                        dataSource={acordeon}
                        itemRender={CardContainer}
                        itemTitleRender={CustomTitle}
                        selectedItems={acordeon}
                        multiple={true}
                        collapsible={false}
                        //animationDuration={400}
                    />
                </div>
            }
        </div>
    );
}