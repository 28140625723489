export interface ZonaToNodoRequest {
    zonaId: number
    nodoId: number
    esOrigen: boolean
}

export const newZonasToNodosRequest : ZonaToNodoRequest = {
    zonaId: 0,
    nodoId: 0,
    esOrigen: false
};