
export interface NodoRequest {    
    nombre: string,
    codigoInterno: number,
    descripcion: string,
    enabled: boolean,
    //estado: number,
}

export const newNodoRequest : NodoRequest = {
    nombre: '',
    codigoInterno: 0,
    descripcion: '',
    enabled: false
};
