import { FC, useEffect, useState } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { AddNodosToLectores, RemoveNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import notify from 'devextreme/ui/notify';
import { newNodosToLectoresRequest } from '../../../types/requests/nodoToLectorRequest';
import { confirm } from 'devextreme/ui/dialog';

interface Props {
    nodoToLectorId: number,
    lectorId: number,
    nodoId: number
}

export const CheckBoxLectorXNodo : FC<Props> = ({ nodoToLectorId, lectorId, nodoId }) => {
    const [checked, setChecked] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        if(nodoToLectorId !== 0) {
            setChecked(true);
            setId(nodoToLectorId);
        }        
    }, [nodoToLectorId])

    const { data: addNodoToLector, loading: loadingPost, error: errorPost, sendPostSubmit } = AddNodosToLectores(newNodosToLectoresRequest);
    const { data: removeNodoToLector, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveNodosToLectores(id);

    useEffect(() => {
        if (addNodoToLector){
            setChecked(true);
            setId(addNodoToLector.id);
        }
           
    }, [addNodoToLector]);

    useEffect(() => {
        if (errorPost){
            setChecked(false);
            notify(errorPost.message, 'Error', 2000);
        } 
    }, [errorPost])

    useEffect(() => {
        if (removeNodoToLector){
            setChecked(false);
            setId(0);
        }           
    }, [removeNodoToLector]);

    useEffect(() => {
        if (errorRemove){
            setChecked(true);
            notify(errorRemove.message, 'Error', 2000);
        }  
    }, [errorRemove]);

    function marcarLectores() {
        let result = confirm("<b>Se va a modificar la relacion en bbdd sin falta de darle a guardar, Desea continuar?</b>", "Confirmar Selección");
        result.then(dialogResult => {
            if (dialogResult) {
                if (checked) {
                    //Eliminar relacion
                    sendRemoveSubmit();
                }
                else {
                    //Añadir relacion
                    const nuevaRelacion = { nodoId: nodoId, lectorId: lectorId };
                    sendPostSubmit(nuevaRelacion);
                }
            } 
          });    
    }

    return (  
        <>
            <CheckBox className="check_centrar_derecha" value={checked} onValueChange={marcarLectores} 
            disabled={loadingPost || loadingRemove} />
        </>
    );
}