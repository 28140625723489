import { FC, useEffect, useState } from "react";
import Accordion from 'devextreme-react/accordion';
import { CardContainer, Tarjeta, TarjetaAntena, TarjetaLector, TarjetaZona, TarjetaGpi, TarjetaGpo, CheckBoxLectorXNodo, CheckBoxZonaXNodo, CustomTitle } from '../../components';
import { GetLectores } from "../../services/lectores/lectores-service";
import { GetZonas } from "../../services/zonas/zonas-service";
import { GetAntenas } from "../../services/antenas/antenas-service";
import { GetGpis } from "../../services/gpis/gpis-service";
import { GetGpos } from "../../services/gpos/gpos-service";
import { AntenaType, GpiType, GpoType, LectorType, ZonaType } from "../../types";
import { Loading } from "../../components/loading/loading";
import { Error } from "../../components/loading/error";
import { GetZonasToNodos } from "../../services/zonasToNodos/zonasToNodos-service";
import { GetNodosToLectores } from "../../services/nodosToLectores/nodosToLectores-service";
import { useHistory } from "react-router-dom";
import Button from 'devextreme-react/button';
import iconoEditar from '../../components/layouts/imgIconosTarjetas/iconoEditar.png';

interface Props {
    idNodo: number
}

export const AcordeonNodos: FC<Props> = ({idNodo}) => {
    const history = useHistory();
    
    const [acordeon, setAcordeon] = useState([{}]);
    
    const { data: lectores, isPending: loadingLectores, error: errLectores } = GetLectores();
    const { data: zonas, isPending: loadingZonas, error: errZonas } = GetZonas();
    const { data: antenas, isPending: loadingAntenas, error: errAntenas } = GetAntenas();
    const { data: gpis, isPending: loadingGpis, error: errGpis } = GetGpis();
    const { data: gpos, isPending: loadingGpos, error: errGpos } = GetGpos();     
    const { data: zonasToNodos, isPending: loadingZonasToNodos, error: errZonasToNodos } = GetZonasToNodos();
    const { data: nodosToLectores, isPending: loadingNodosToLectores, error: errNodosToLectores } = GetNodosToLectores();
    
    useEffect(() => {
        function onClickButtonEditar(nombre: string, id: number) {
            switch (nombre) {               
                case "Antena":
                    history.push('/antena/' + id);
                    break;
                case "Gpi":
                    history.push('/gpi/' + id);
                    break;
                case "Gpo":
                    history.push('/gpo/' + id);
                    break;
                default:
                    break;
            }
        }

        let tarjetasLectores = null;
        let tarjetasZonasOrigen = null;
        let tarjetasZonasDestino = null;
        let tarjetasAntenas = null;
        let tarjetasGpis = null;
        let tarjetasGpos = null;

        if (lectores && zonas && antenas && gpis && gpos) {
            const renderListadoLectores = (lector: LectorType) => {            
                let resultado = null;     
                let nodosToLectoresNodo = nodosToLectores?.find(x => x.nodoId === idNodo && x.lectorId === lector.id);     
                resultado = <Tarjeta key={lector.id}>
                                <TarjetaLector lector={lector} isRelacion={true} />
                                <CheckBoxLectorXNodo nodoToLectorId={nodosToLectoresNodo?.id || 0} lectorId={lector.id} nodoId={idNodo} />  
                            </Tarjeta>
                return resultado;
            };
            tarjetasLectores = lectores?.map(lector => renderListadoLectores(lector));
    
            const renderlistadoZonasOrigen = (zona: ZonaType) => {
                let resultado = null; 
                let zonasToNodosZona = zonasToNodos?.find(x => x.zonaId === zona.id && x.nodoId === idNodo && x.esOrigen === true);
                resultado = <Tarjeta key={zona.id}>
                                <TarjetaZona zona={zona} isRelacion={true} />
                                <CheckBoxZonaXNodo zonaToNodoId={zonasToNodosZona?.id || 0} 
                                                    zonaId={zona.id} 
                                                    nodoId={idNodo} 
                                                    esOrigen={true} />
                            </Tarjeta>
                return resultado;
            };
            tarjetasZonasOrigen = zonas?.map(zona => renderlistadoZonasOrigen(zona));
    
            const renderlistadoZonasDestino = (zona: ZonaType) => {
                let resultado = null;  
                let zonasToNodosZona = zonasToNodos?.find(x => x.zonaId === zona.id && x.nodoId === idNodo && x.esOrigen === false);         
                resultado = <Tarjeta key={zona.id}>
                                <TarjetaZona zona={zona} isRelacion={true} />
                                <CheckBoxZonaXNodo zonaToNodoId={zonasToNodosZona?.id || 0}
                                                    zonaId={zona.id} 
                                                    nodoId={idNodo} 
                                                    esOrigen={false} />
                            </Tarjeta>
                return resultado;
            };
            tarjetasZonasDestino = zonas?.map(zona => renderlistadoZonasDestino(zona));
    
            const renderlistadoAntenas = (antena: AntenaType) => {
                let resultado = null;            
                resultado = <Tarjeta key={antena.id}>
                                <TarjetaAntena antena={antena} isRelacion={true} />
                                <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Antena", antena.id)}></Button>
                            </Tarjeta>            
                return resultado;
            };    
            const a = antenas?.filter(a => a.nodoId === idNodo);
            tarjetasAntenas = a?.map(antena => renderlistadoAntenas(antena));
    
            const renderlistadoGpis = (gpi: GpiType) => {
                let resultado = null;                  
                resultado = <Tarjeta key={gpi.id}>
                                <TarjetaGpi gpi={gpi} isRelacion={true} /> 
                                <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Gpi", gpi.id)}></Button>                
                            </Tarjeta>
                
                return resultado;
            };    
            const g = gpis?.filter(g => g.nodoId === idNodo);
            tarjetasGpis = g?.map(gpi => renderlistadoGpis(gpi));
    
            const renderlistadoGpos = (gpo: GpoType) => {
                let resultado = null;                  
                resultado = <Tarjeta key={gpo.id}>
                                <TarjetaGpo gpo={gpo} isRelacion={true} />
                                <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Gpo", gpo.id)}></Button>
                            </Tarjeta>
              
                return resultado;
            };    
            const go = gpos?.filter(g => g.nodoId === idNodo);
            tarjetasGpos = go?.map(gpo => renderlistadoGpos(gpo));
        }
        const componentes = [{
            'Title': 'Lectores',
            'children': tarjetasLectores
        }, {
            'Title': 'Zonas Origen',
            'children': tarjetasZonasOrigen
        }, {
            'Title': 'Zonas Destino',
            'children': tarjetasZonasDestino
        }, {
            'Title': 'Antenas',
            'children': tarjetasAntenas
        }, {
            'Title': 'Gpis',
            'children': tarjetasGpis
        }, {
            'Title': 'Gpos',
            'children': tarjetasGpos
        }];
        setAcordeon(componentes);
    }, [lectores, zonas, antenas, gpis, gpos, zonasToNodos, nodosToLectores, idNodo,history])   

    return (
        <div>
            {(loadingLectores || loadingZonas || loadingAntenas || loadingGpis || loadingGpos || loadingZonasToNodos || loadingNodosToLectores) && <Loading />}
            {errLectores && <Error error={errLectores} />}
            {errZonas && <Error error={errZonas} />}
            {errAntenas && <Error error={errAntenas} />}
            {errGpis && <Error error={errGpis} />}
            {errGpos && <Error error={errGpos} />}
            {errZonasToNodos && <Error error={errZonasToNodos} />}
            {errNodosToLectores && <Error error={errNodosToLectores} />}
            {(lectores && zonas && antenas && gpis && gpos && zonasToNodos) &&
                <div id="accordion">
                    <Accordion
                        dataSource={acordeon}
                        itemRender={CardContainer}
                        itemTitleRender={CustomTitle}
                        selectedItems={acordeon}
                        multiple={true}
                        collapsible={false}
                        //animationDuration={400}
                    />
                </div>
            }
        </div>
    );
}