import { FC } from 'react';
import { CardImg } from 'reactstrap';
import { LoginForm, SingleCard } from './components';
import logoBiraid from './components/login-form/logoBiraid.png';

export const UnauthenticatedContent: FC = () => {

    return (
        <div className='centrado'>
            <div>
                <CardImg style={{ margin: 'auto auto' }} src={logoBiraid} alt="Card image cap" />
                {/* <img src={logoBiraid} alt="" /> */}
                <SingleCard title="Login" description="">
                    <LoginForm />                 
                </SingleCard>   
                {/* <img src={logo} className="img-responsive" alt="titulo" />         */}
            </div>
        </div>
    );
}