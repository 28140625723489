import { FC, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,  
  StringLengthRule,
  PatternRule,
  GroupItem
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './reset-password.scss';
import { SingleCard } from '../layouts/single-card/single-card';
import { ResetPassword } from '../../services/users/users-service';
import { ResetPasswordRequest, newResetPasswordRequest } from '../../types/requests/resetPasswordRequest';
import { ResetPasswordResponse } from '../../types/responses/resetPasswordResponse'; 
import iconoOjo from '../../components/layouts/imgIconosTarjetas/iconoOjo.png';
import iconoOjoOculto from '../../components/layouts/imgIconosTarjetas/iconoOjoOculto.png';

const notificationText = 'La contraseña se ha cambiado correctamente. Vuelva a iniciar sesion.';

export const ResetPasswordFC: FC = () => {
    const history = useHistory();
      
    const [showPassword, setShowPassword] = useState(false);
    const [iconoBotonMostrarPassword, setIconoBotonMostrarPassword] = useState(iconoOjoOculto);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [iconoBotonMostrarPasswordConfirm, setIconoBotonMostrarPasswordConfirm] = useState(iconoOjoOculto);

    const onSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        //var idUsuario = urlParams.get('uid');
        var token = urlParams.get('token');

        const nuevoResetPasswordRequest: ResetPasswordRequest = {          
            token: token || '',
            newPassword: String(datos['newPassword']),
            confirmPassword: String(datos['confirmPassword']),
            //email: String(datos['email'])            
        }
        if (nuevoResetPasswordRequest.newPassword === nuevoResetPasswordRequest.confirmPassword)
        {
            sendPostSubmit(nuevoResetPasswordRequest);   
        }
        else
        {
            notify('Debe de confirmar correctamente la contraseña.', 'error', 4000); 
        }
    }

    const { data, error, loading, sendPostSubmit } = ResetPassword<ResetPasswordResponse, ResetPasswordRequest>(newResetPasswordRequest);
    useEffect(() => {
        if(data){
            history.push('/');
            notify(notificationText, 'success', 4000);
        }
    }, [data, history]);
   
   
    if (error) {
        notify(error.message, 'error', 4000);
    }  

    function mostrarPassword()
    {
        if(showPassword)
            setIconoBotonMostrarPassword(iconoOjoOculto);
        else
            setIconoBotonMostrarPassword(iconoOjo);
        setShowPassword(!showPassword);    
    }

    function mostrarPasswordConfirm()
    {
        if(showPasswordConfirm)
            setIconoBotonMostrarPasswordConfirm(iconoOjoOculto);
        else
            setIconoBotonMostrarPasswordConfirm(iconoOjo);
        setShowPasswordConfirm(!showPasswordConfirm);    
    }

    return (
    <div className='centrado'>
        <div>
            {!data && (
            <SingleCard title="Cambiar Contraseña" description="">
                <form className={"reset-password"} onSubmit={onSubmit}>
                <Form formData={newResetPasswordRequest} disabled={loading}>
                    <GroupItem colCount={8}>                    
                        <Item colSpan={6}
                        dataField={"newPassword"}
                        editorType={"dxTextBox"}
                        editorOptions={showPassword ? { stylingMode: 'filled', placeholder: 'Nueva Contraseña', mode: 'text' } : { stylingMode: 'filled', placeholder: 'Nueva Contraseña', mode: 'password' } }
                        >
                            <StringLengthRule min={6} max={255} message={'La nueva contraseña debe ser mayor a 6 caracteres y menor de 255'}></StringLengthRule>
                            <PatternRule pattern={"(?=.*[0-9])(?=.*[@$!%?&.,-])(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"} message={"Debe tener al menos una mayúscula, una minúscula, un numero y un signo"}></PatternRule>
                            <RequiredRule message="contraseña requida" />                  
                            <Label visible={false} />
                        </Item>
                        <ButtonItem colSpan={2}>
                            <ButtonOptions   icon={iconoBotonMostrarPassword} onClick={mostrarPassword} />
                        </ButtonItem> 
                    </GroupItem>
                    <GroupItem colCount={8}>
                        <Item colSpan={6}
                        dataField={"confirmPassword"}
                        editorType={"dxTextBox"}
                        editorOptions={showPasswordConfirm ? { stylingMode: 'filled', placeholder: 'Confirmar Contraseña', mode: 'text' } : { stylingMode: 'filled', placeholder: 'Confirmar Contraseña', mode: 'password' }}
                        >
                            <StringLengthRule min={6} max={255} message={'La nueva contraseña debe ser mayor a 6 caracteres y menor de 255'}></StringLengthRule>
                            <PatternRule pattern={"(?=.*[0-9])(?=.*[@$!%?&.,-])(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"} message={"Debe tener al menos una mayúscula, una minúscula, un numero y un signo"}></PatternRule>
                            <RequiredRule message="contraseña requida" />                  
                            <Label visible={false} />
                        </Item>
                        <ButtonItem colSpan={2}>
                            <ButtonOptions   icon={iconoBotonMostrarPasswordConfirm} onClick={mostrarPasswordConfirm} />
                        </ButtonItem> 
                    </GroupItem>
                    <ButtonItem>
                    <ButtonOptions
                        elementAttr={submitButtonAttributes}
                        width={"100%"}
                        type={"default"}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                        {loading ? (
                            <LoadIndicator
                            width={"24px"}
                            height={"24px"}
                            visible={true}
                            />
                        ) : (
                            "Cambiar"
                        )}
                        </span>
                    </ButtonOptions>
                    </ButtonItem>               
                </Form>
                </form>
            </SingleCard>
            )}
        </div>
    </div>
    );
}

//const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Nueva Contraseña', mode: 'password' };
//const confirmPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirmar Contraseña', mode: 'password' };
const submitButtonAttributes = { class: 'submit-button' };
