import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import { StoreContext } from '../../../contexts/context';
import { GetPermisosToRoles } from '../../../services/permisosToRoles/permisosToRoles-service';
import { GetRoles } from '../../../services/roles/roles-service';
import { PermisoToRolType, RolType, UsuarioType } from '../../../types';
import IconoUsuarios from '../imgIconosTarjetas/iconoUsuario.png';

interface Props {
    user: UsuarioType
    isRelacion: boolean
}

export const TarjetaUser: FC<Props> = ({ user, isRelacion }) => {

    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;
    
    const { data: roles } = GetRoles();
    const { data: permisosToRoles } = GetPermisosToRoles();
       
    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {       
        //Seleccionado usuario    
        if (hovered.user === user.id) {
            clasescss = "estilo fondoactivado";
        }

        //Seleccionado rol
        if (roles !== null)
        {
            const rolActivoUsuario = (roles as RolType[]).find(r => r.id === user.rolId && user.rolId === hovered.rol);
            if (rolActivoUsuario) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado permiso
        if (permisosToRoles !== null)
        {
            const permisoToRol = (permisosToRoles as PermisoToRolType[]).find(p2r => p2r.rolId === user.rolId && p2r.permisoId === hovered.permiso);
            if (permisoToRol) {
                clasescss = "estilo fondoactivado";
            }
        }
    }

    return (       
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarUser', payload:user.id})}
                                     onMouseLeave={() => dispatchHovered({type:'actualizarUser', payload:0})} >
            <CardHeader className="headerFont"> {user.firstname } {user.lastName} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoUsuarios} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px`}}>
                        <CardText className="divididoLineaDescripcion">Usuario: {user.userName}</CardText>
                        <CardText className="divididoLineaDescripcion">Correo: {user.email}</CardText>
                        { roles &&
                        <CardText className="divididoLineaDescripcion">Rol: {(roles as RolType[]).filter(x => x.id === user.rolId).map(x => x.nombre)}</CardText>
                        }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};