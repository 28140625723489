import { ActionToken } from "../actions/action-types";

export const tokenReducer = (state: string, action: ActionToken): string => {
    switch (action.type) {       
         case 'Add':
            localStorage.setItem('token', action.payload);
            return action.payload;
        case 'Delete':
            localStorage.removeItem('token');
            localStorage.clear();
            return '';
        default:
            return state;
    }    
}