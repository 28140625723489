export interface HoveredType {
    nodo: number
    lector: number
    zona: number
    antena: number
    gpi: number
    gpo: number
    user: number
    rol: number
    permiso: number
}

export const newHovered : HoveredType = {nodo: 0, lector: 0, zona: 0, antena: 0, gpi: 0, gpo: 0, user: 0, rol: 0, permiso: 0};