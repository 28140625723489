import { FC, useReducer } from 'react';
import { useScreenSizeClass } from './utils/media-query';
import { StoreContext } from './contexts/context';
import { hoveredReducer } from './reducers/hovered/hovered-reducer';
import { usuarioReducer } from './reducers/usuario/usuario-reducer';
import { tokenReducer } from './reducers/token/token-reducer';
import { newHovered, newUsuario } from './types';
//import { useToken } from './hooks/token-hook';
import { UnauthenticatedContent } from './UnauthenticatedContent';
import { Content } from './Content';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import './dx-styles.scss';
import { ForgotPasswordFC } from './components/forgot-password/forgot-password';
import { ResetPasswordFC } from './components/reset-password/reset-password';

import { Route, Switch } from 'react-router-dom';

const App: FC = () => {

    const screenSizeClass = useScreenSizeClass();
    const useHoveredSlice = useReducer(hoveredReducer, newHovered);
    const useUsuarioSlice = useReducer(usuarioReducer, newUsuario);
    const useTokenSlice = useReducer(tokenReducer, localStorage.getItem('token')?.toString() || '' );

    //const { token, setToken } = useToken();
    
    const [token,] = useTokenSlice;  
        
    if (!token)
    {
        return (
   
            <StoreContext.Provider value={{
                useHoveredSlice: useHoveredSlice,
                useUsuarioSlice: useUsuarioSlice,
                useTokenSlice: useTokenSlice                   
            }}>

        <Switch>
            <Route
                exact
                key={'/forgot-password'}
                path={'/forgot-password'}
            >
                <div className={`app ${screenSizeClass} con-fondo` }>
                    <ForgotPasswordFC />
                </div>
            </Route>
            <Route
                exact
                key={'/reset-password'}
                path={'/reset-password'}                
            >
                <div className={`app ${screenSizeClass} con-fondo`}>
                    <ResetPasswordFC />
                </div>
            </Route>  
             <Route
                exact
                key={'/'}
                path={'/'}
            >
                <div className={`app ${screenSizeClass} con-fondo`}>                   
                    <UnauthenticatedContent />
                </div>
            </Route>
        </Switch>

      </StoreContext.Provider>


        );         
    }

    return (           
        <StoreContext.Provider value={{
            useHoveredSlice: useHoveredSlice,
            useUsuarioSlice: useUsuarioSlice,
            useTokenSlice: useTokenSlice                   
        }}>
            {/* <div> */}
            <div className={`app ${screenSizeClass}`} >
                <Content />
            </div>
            {/* </div> */}
        </StoreContext.Provider> 
    )
}

export default App;



// que no haya token +
    //// a casa al login
    //// -> no hay usuario 401    
    // hay token
    //// que el token no sirva +
    //// a casa
    // -> no hay usuario 4013
    // El token sirve ++
    //// Usuario!!!
    //// A dentro!!!

    // Se llama a GetUserToken
    // error 
    //// o el token no vale
    //// o no hay token
    // bien
    // token ok y ademas hay usuario
