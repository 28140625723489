// Clase propia para construir los metodos para las llamadas al ws
import { gpisUrl } from '../../utils/rutasllamadasapi';
import { GpiType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { GpiRequest } from '../../types/requests/gpiRequest';

export const GetGpis = () => {
    return useFetch<GpiType[]>(gpisUrl);   
}

export const GetGpi = (id: number) => {
    return useFetch<GpiType>(gpisUrl + id);
}

export const AddGpi = (gpi: GpiRequest) => {
    return usePostHook<GpiType, GpiRequest>(gpisUrl, gpi);
}

export const UpdateGpi = (id: number, gpi: GpiRequest) => {
    return usePutHook<GpiType, GpiRequest>(gpisUrl + id, gpi);
}

export const RemoveGpi = (id: number) => {
    return useDeleteHook<number>(gpisUrl + id);
}