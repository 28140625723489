import { FC, useContext, useEffect, useState } from 'react';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    EmailRule,
    StringLengthRule,
    GroupItem
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import './login-form.scss';
import { Login } from '../../services/users/users-service';
import { useHistory } from 'react-router';
import { LoginRequest, newLoginRequest } from '../../types/requests/loginRequest';
import { LoginResponse } from '../../types/responses/loginResponse';
import { Error } from '../../components/loading/error';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../contexts/context';
import iconoOjo from '../../components/layouts/imgIconosTarjetas/iconoOjo.png';
import iconoOjoOculto from '../../components/layouts/imgIconosTarjetas/iconoOjoOculto.png';

export const LoginForm: FC = () => {
    const history = useHistory();
    const { useTokenSlice } = useContext(StoreContext);
    const [, dispatchToken] = useTokenSlice;
      
    const [showPassword, setShowPassword] = useState(false);
    const [iconoBotonMostrarPassword, setIconoBotonMostrarPassword] = useState(iconoOjoOculto);

    const onSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        const nuevoLoginRequest: LoginRequest = {
            email: String(datos['email']),
            password: String(datos['password'])
        }
        sendPostSubmit(nuevoLoginRequest);
    }

    newLoginRequest.email = '';
    newLoginRequest.password= '';

    const { data, error, loading, sendPostSubmit } = Login<LoginResponse, LoginRequest>(newLoginRequest);
    useEffect(() => {
        if(data){
            //setToken(data.token || '');
            dispatchToken({type: "Add", payload: data.token || ''})
            history.push('/');
        }
    }, [data, history, dispatchToken]);
   
    function mostrarPassword()
    {
        if(showPassword)
            setIconoBotonMostrarPassword(iconoOjoOculto);
        else
            setIconoBotonMostrarPassword(iconoOjo);
        setShowPassword(!showPassword);    
    }
    // if (error) {
    //     notify(error.message, 'error', 2000);
    // }  

    return (
        <div>            
            {error && <Error error={error} />}
            {!data &&
                <div className={'login-center'}>
                    <form className={'login-form'} onSubmit={onSubmit}>
                        <Form formData={newLoginRequest} disabled={loading}>
                            <Item
                                dataField={'email'}
                                editorType={'dxTextBox'}
                                editorOptions={emailEditorOptions}
                            >
                                <StringLengthRule max={255} message='El email no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                <RequiredRule message="Email is required" />
                                <EmailRule message="Email is invalid" />
                                <Label visible={false} />
                            </Item>
                            <GroupItem colCount={8}>
                                <Item colSpan={6}
                                    dataField={'password'}
                                    editorType={'dxTextBox'}
                                    editorOptions={showPassword ? { stylingMode: 'filled', placeholder: 'Contraseña', mode: 'text' } : { stylingMode: 'filled', placeholder: 'Contraseña', mode: 'password' } } 
                                >
                                    <StringLengthRule max={255} message='La password no puede ser mayor a 255 caracteres' ></StringLengthRule>                                                                       
                                    <Label visible={false} />
                                </Item>
                                <ButtonItem colSpan={2}>
                                    <ButtonOptions   icon={iconoBotonMostrarPassword} onClick={mostrarPassword} />
                                </ButtonItem> 
                            </GroupItem>                           
                            <ButtonItem>
                                <ButtonOptions
                                    width={'100%'}
                                    type={'default'}
                                    useSubmitBehavior={true}
                                >
                                    <span className="dx-button-text">
                                        {
                                            loading
                                                ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                : 'Login'
                                        }
                                    </span>
                                </ButtonOptions>
                            </ButtonItem>
                            <Item>                               
                                <div className={'link'}>                                   
                                    <Link to={"/forgot-password"}>¿Olvidó su contraseña?</Link>
                                </div>
                            </Item>
                        </Form>
                    </form>
                </div>
            }
        </div>
    );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
//const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Contraseña', mode: 'password' };
//const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
