export const enum_estado = [    
    { id: 1, nombre: "Desconectado" },
    { id: 2, nombre: "Conectado" },
    { id: 3, nombre: "Inventario" }
];

export const enum_tipo = [    
    { id: 100, nombre: "ImpinjR420" },
    { id: 101, nombre: "ImpinjR700" },
    { id: 200, nombre: "Zebra" },
    { id: 300, nombre: "FeigISCRU1002" },
    { id: 301, nombre: "FeigHyWear" },
];