import { useEffect, useState } from "react";
import { ErrorType } from '../types';
import { post } from '../services/httpService';

export function usePostHook<T, J>(url: string, initialBody: J) {
    const [data, setData] = useState<T | null>(null);
    const [loading, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | null>(null);

    const [execute, setExecute] = useState<Boolean>(false);

    const [body, setBody] = useState<J>(initialBody);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        if (execute) {
            setIsPending(true);
            setError(null);
            post<T, J>(url, body, signal)
                .then(data => {
                    setIsPending(false);
                    setError(null);
                    setData(data);
                    setExecute(false);
                })
                .catch((err: ErrorType) => {
                    if(err.code!==20){
                        console.log(err);
                        setIsPending(false);
                        setError(err);
                        setExecute(false);
                    }
                });
        }     
        
        return () => abortController.abort();

    }, [execute, body, url]);

    const sendPostSubmit = (body : J) => {
        setBody(body);  
        setExecute(true);
    };

    return {data, loading, error, sendPostSubmit};
}