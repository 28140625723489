import { FC, useEffect, useState } from 'react';
import '../datos-generales/datos-generales.scss';
import { useHistory } from 'react-router';
import { Tarjeta, TarjetaAniadir, CardContainer, CustomTitle, TarjetaUser, TarjetaRol, TarjetaPermiso } from '../../components';
import Accordion from 'devextreme-react/accordion';
import Button from 'devextreme-react/button';
import { PermisoType, RolType, UsuarioType } from '../../types';
import iconoEditar from '../../components/layouts/imgIconosTarjetas/iconoEditar.png';
import { GetUsuarios } from '../../services/users/users-service';
import { GetRoles } from '../../services/roles/roles-service';
import { GetPermisos } from '../../services/permisos/permisos-service';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';

export const DatosUsuarios: FC = () => {
    const history = useHistory();
     
    const { data: users, isPending: loadingUsuarios, error: errUsuarios } = GetUsuarios();
    const { data: roles, isPending: loadingRoles, error: errRoles } = GetRoles();
    const { data: permisos, isPending: loadingPermisos, error: errPermisos } = GetPermisos();
    
    const [acordeon, setAcordeon] = useState([{}]);   

    useEffect(() => {
        function onClickButtonEditar(nombre: string, id: number) {
            switch (nombre) {
                case "Usuario":
                    history.push('/usuario/' + id);
                    break;
                case "Rol":
                    history.push('/roles/' + id);
                    break;
                case "Permiso":
                    history.push('/permisos/' + id);
                    break;
                default:
                    break;
            }
        }

        let tarjetasUsers = null;
        if (users !== null)
        {
            tarjetasUsers = (users as UsuarioType[]).map(user =>
            <Tarjeta key={user.id}>
                <TarjetaUser user={user} isRelacion={false} />
                <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Usuario", user.id)}>
                </Button>
            </Tarjeta>);
            tarjetasUsers.push(<Tarjeta key={0}><TarjetaAniadir tipo="Users" /></Tarjeta>);
        }

        let tarjetasRoles = null;
        if (roles !== null)
        {
            tarjetasRoles = (roles as RolType[]).map(rol =>
            <Tarjeta key={rol.id}>
                <TarjetaRol rol={rol} isRelacion={false} />
                <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Rol", rol.id)}>
                </Button>
            </Tarjeta>);
            tarjetasRoles.push(<Tarjeta key={0}><TarjetaAniadir tipo="Roles" /></Tarjeta>);
        }

        let tarjetasPermisos = null;
        if (permisos !== null)
        {
            tarjetasPermisos = (permisos as PermisoType[]).map(permiso =>
            <Tarjeta key={permiso.id}>
                <TarjetaPermiso permiso={permiso} isRelacion={false} />
                <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Permiso", permiso.id)}>
                </Button>
            </Tarjeta>);    
            tarjetasPermisos.push(<Tarjeta key={0}><TarjetaAniadir tipo="Permisos" /></Tarjeta>);
        }

        const componentes = [{
            'Title': 'Usuarios',
            'children': tarjetasUsers
        }
        , {
            'Title': 'Roles',
            'children': tarjetasRoles
        }, {
            'Title': 'Permisos',
            'children': tarjetasPermisos
        }    
        ];
        
        setAcordeon(componentes);
    }, [users, roles, permisos, history])

    return (
        <div>
            <br />
            {(loadingUsuarios || loadingRoles || loadingPermisos) && <Loading />}
            {errUsuarios && <Error error={errUsuarios} />}
            {errRoles && <Error error={errRoles} />}
            {errPermisos && <Error error={errPermisos} />}
            { (users && roles && permisos ) &&
                <div id="accordion">
                    <Accordion
                        dataSource={acordeon}
                        itemRender={CardContainer}
                        itemTitleRender={CustomTitle}
                        multiple={true}
                        // collapsible={false}
                        // selectedItems={[acordeon[0], acordeon[1], acordeon[2]]}                        
                        //animationDuration={400}
                    />
                </div>
            }
            </div>
        );
    }

