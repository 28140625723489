import { FC, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    Label,
    StringLengthRule,
    NumericRule   
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './datosNodo.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import iconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import { AddNodo, GetNodo, RemoveNodo, UpdateNodo } from '../../services/nodos/nodos-service';
import notify from 'devextreme/ui/notify';
import { newNodoRequest, NodoRequest } from '../../types/requests/nodoRequest';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import { AcordeonNodos } from './acordeonNodos';
import { confirm } from 'devextreme/ui/dialog';

interface Params {
    id: string
}

export const DatosNodo: FC = () => {

    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;

    const { data: nodo, isPending, error } = GetNodo(idNumero); 
    
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        const nodoNuevo: NodoRequest = {
            nombre: String(datos["nombre"]),
            codigoInterno: Number(datos["codigoInterno"]),
            descripcion: String(datos["descripcion"]),
            enabled: Boolean(datos["enabled"])
        }

        if (id != null) {
            sendPutSubmit(nodoNuevo); 
        } else {
            sendPostSubmit(nodoNuevo);
        }       
    };       
  
    const { data: updateNodo, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdateNodo(idNumero, newNodoRequest);//formData.current);
    const { data: addNodo, loading: loadingPost, error: errorPost, sendPostSubmit } = AddNodo(newNodoRequest);
    const { data: removeNodo, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveNodo(idNumero);

    useEffect(() => {
        if (updateNodo)
            history.push('/datosGenerales');
    }, [updateNodo, history]);

    useEffect(() => {
        if (addNodo)
            history.push('/nodo/' + addNodo.id);
    }, [addNodo, history]);

    useEffect(() => {
        if (removeNodo)
            history.push('/datosGenerales');
    }, [removeNodo, history]);

    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])         

    function eliminar() {
        let result = confirm("<b>Se va a borrar el nodo, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
          });       
    }

    return (
        <div>
            {isPending && <Loading />}
            {error && <Error error={error} />}
            {nodo && (
                <div>
                    <h2 className={'content-block'}>Nodo</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={nodo}>
                            <GroupItem colCount={4}>
                                <SimpleItem render={logoNodo}>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField="nombre" >
                                        <StringLengthRule max={255} message='El nombre no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                        <RequiredRule message="El nombre es requerido" />
                                    </SimpleItem>
                                    <SimpleItem dataField="descripcion" name="descripcion" >
                                        <StringLengthRule max={255} message='La descripcion no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                    </SimpleItem>                                   
                                    <SimpleItem dataField="codigoInterno" name="codigoInterno" editorType={'dxNumberBox'} 
                                            editorOptions={{max:999999}}>
                                        <NumericRule />
                                        <RequiredRule message="El codigo interno es requerido" />
                                    </SimpleItem>                                    
                                    <SimpleItem editorType='dxCheckBox' dataField="enabled" name="enabled">
                                        <Label text="Habilitar" />
                                    </SimpleItem>                                    
                                </GroupItem>                               
                            </GroupItem>
                            <GroupItem colCount={12}>
                                <EmptyItem colSpan={10} />
                                <ButtonItem >
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPost || loadingPut)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem>
                                    <ButtonOptions icon={iconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove} />
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                    </form>
                    <br />
                    { idNumero!==0 && <AcordeonNodos idNodo={idNumero} /> }
                </div>
            )}
        </div>
    );
}

function logoNodo() {
    return (
        <div className="form-logo-nodo"></div>
    );
}