import { Tarjeta, TarjetaAntena, TarjetaLector, TarjetaNodo, TarjetaZona, TarjetaAniadir, TarjetaGpi, TarjetaGpo, CardContainer, CustomTitle } from '../../components';
import './datos-generales.scss';
import Accordion from 'devextreme-react/accordion';
import Button from 'devextreme-react/button';
import { useHistory } from "react-router-dom";
import { FC, useEffect, useState } from 'react';
import { NodoType, LectorType, ZonaType, AntenaType, GpiType, GpoType } from '../../types';
import iconoEditar from '../../components/layouts/imgIconosTarjetas/iconoEditar.png';
import { GetNodos } from '../../services/nodos/nodos-service';
import { GetLectores } from '../../services/lectores/lectores-service';
import { GetZonas } from '../../services/zonas/zonas-service';
import { GetAntenas } from '../../services/antenas/antenas-service';
import { GetGpis } from '../../services/gpis/gpis-service';
import { GetGpos } from '../../services/gpos/gpos-service';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
//import 'bootstrap/dist/css/bootstrap.css';

export const DatosGenerales : FC = () => { 

    const history = useHistory();
       
    const { data: nodos, isPending: loadingNodos, error: errNodos } = GetNodos();
    const { data: lectores, isPending: loadingLectores, error: errLectores } = GetLectores();
    const { data: zonas, isPending: loadingZonas, error: errZonas } = GetZonas();
    const { data: antenas, isPending: loadingAntenas, error: errAntenas } = GetAntenas();
    const { data: gpis, isPending: loadingGpis, error: errGpis } = GetGpis();
    const { data: gpos, isPending: loadingGpos, error: errGpos } = GetGpos();

    const [acordeon, setAcordeon] = useState({});
        
    useEffect(() => {
        function onClickButtonEditar(nombre: string, id: number) {
            switch (nombre) {
                case "Nodo":
                    history.push('/nodo/' + id);
                    break;
                case "Lector":
                    history.push('/lector/' + id);
                    break;
                case "Zona":
                    history.push('/zona/' + id);
                    break;
                case "Antena":
                    history.push('/antena/' + id);
                    break;
                case "Gpi":
                    history.push('/gpi/' + id);
                    break;
                case "Gpo":
                    history.push('/gpo/' + id);
                    break;
                default:
                    break;
            }
        } 

        let tarjetasNodos = null;
        if (nodos !== null)
        {
            tarjetasNodos = (nodos as NodoType[]).map(nodo =>
                <Tarjeta key={nodo.id}>
                    <TarjetaNodo nodo={nodo} isRelacion={false} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Nodo", nodo.id)}></Button>
                </Tarjeta>);
    
            tarjetasNodos.push(<Tarjeta key={0}><TarjetaAniadir tipo="Nodos" /></Tarjeta>);
        }
       
        let tarjetasLectores = null;
        if (lectores !== null)
        {
            tarjetasLectores = (lectores as LectorType[]).map(lector =>
                <Tarjeta key={lector.id}>
                    <TarjetaLector lector={lector} isRelacion={false} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Lector", lector.id)}></Button>
                </Tarjeta>);
            tarjetasLectores.push(<Tarjeta key={0}><TarjetaAniadir tipo="Lectores" /></Tarjeta>);
        }
       
        let tarjetasZonas = null;
        if (zonas !== null)
        {
            tarjetasZonas = (zonas as ZonaType[]).map(zona =>
                <Tarjeta key={zona.id}>
                    <TarjetaZona zona={zona} isRelacion={false} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Zona", zona.id)}></Button>
                </Tarjeta>);
            tarjetasZonas.push(<Tarjeta key={0}><TarjetaAniadir tipo="Zonas" /></Tarjeta>);
        }
        
        let tarjetasAntenas = null;
        if (antenas !== null)
        {
            tarjetasAntenas = (antenas as AntenaType[]).map(antena =>
                <Tarjeta key={antena.id}>
                    <TarjetaAntena antena={antena} isRelacion={false} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Antena", antena.id)}></Button>
                </Tarjeta>);
            tarjetasAntenas.push(<Tarjeta key={0}><TarjetaAniadir tipo="Antenas" /></Tarjeta>);
        }   
    
        let tarjetasGpis = null;
        if (gpis !== null)
        {
            tarjetasGpis = (gpis as GpiType[]).map(gpi =>
                <Tarjeta key={gpi.id}>
                    <TarjetaGpi gpi={gpi} isRelacion={false} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Gpi", gpi.id)}></Button>
                </Tarjeta>);
            tarjetasGpis.push(<Tarjeta key={0}><TarjetaAniadir tipo="Gpis" /></Tarjeta>);
        }
      
        let tarjetasGpos = null;
        if (gpos !== null)
        {
            tarjetasGpos = (gpos as GpoType[]).map(gpo =>
                <Tarjeta key={gpo.id}>
                    <TarjetaGpo gpo={gpo} isRelacion={false} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Gpo", gpo.id)}></Button>
                </Tarjeta>);
            tarjetasGpos.push(<Tarjeta key={0}><TarjetaAniadir tipo="Gpos" /></Tarjeta>);    
        }

        const componentes = [{
            'Title': 'Nodos',
            'children': tarjetasNodos
         }, {
            'Title': 'Zonas',
            'children': tarjetasZonas
        }, {
            'Title': 'Lectores',
            'children': tarjetasLectores
        }, {
            'Title': 'Antenas',
            'children': tarjetasAntenas
        }, {
            'Title': 'Gpis',
            'children': tarjetasGpis
        }, {
            'Title': 'Gpos',
            'children': tarjetasGpos
        } ];

        setAcordeon(componentes);
    }, [nodos, lectores, antenas, zonas, gpis, gpos, history])

    return (
        <div>
        <br/>
        {(loadingNodos || loadingLectores || loadingZonas || loadingAntenas || loadingGpis || loadingGpos) && <Loading />}
        {errNodos && <Error error={errNodos} />}
        {errLectores && <Error error={errLectores} />}
        {errZonas && <Error error={errZonas} />}
        {errAntenas && <Error error={errAntenas} />}
        {errGpis && <Error error={errGpis} />}
        {errGpos && <Error error={errGpos} />}
        { (nodos && lectores && zonas && antenas && gpis && gpos) && 
            <div id="accordion" >
            <Accordion                                   
                dataSource={acordeon}           
                itemRender={CardContainer}
                itemTitleRender={CustomTitle}
                multiple={true}                 
                //animationDuration={400}
                
            />
            </div>
        }
        </div>
    );

}; 
