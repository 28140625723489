export const navigation = [    
    {
        text: 'Dashboard',
        //path: '',
        icon: 'home',
        items: [
            {
                text: 'Tags',
                path: '/dashboard/finalform',
            },
            {
                text: 'Lecturas',
                path: '/dashboard/cosas',
            }
        ]
    },
    {
        text: 'Configuracion',
        icon: 'folder',       
        items: [
            {
                text: 'Datos Generales',
                path: '/datosGenerales',
            },
            {
                text: 'Usuarios',
                path: '/datosUsuarios'
            }
        ]
    },    
  ];