import { FC, ReactChild } from 'react';
//import { Carousel2 } from '../carousel/carousel2';
import { Carousel } from '../carousel/carousel';

interface Props {
    Title: string
    children: ReactChild[]
}

export const CardContainer: FC<Props> = ({Title, children}) => {
    return (
        <div id ={Title}>           
            {/* <Carousel2 children={children}/> */}
            <Carousel children={children}/>
        </div>
    );
}