// Clase propia para construir los metodos para las llamadas al ws
import { gposUrl } from '../../utils/rutasllamadasapi';
import { GpoType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { GpoRequest } from '../../types/requests/gpoRequest';

export const GetGpos = () => {
    return useFetch<GpoType[]>(gposUrl);
}

export const GetGpo = (id: number) => {
    return useFetch<GpoType>(gposUrl + id);
}

export const AddGpo = (gpo: GpoRequest) => {
    return usePostHook<GpoType, GpoRequest>(gposUrl, gpo);
}

export const UpdateGpo = (id: number, gpo: GpoRequest) => {
    return usePutHook<GpoType, GpoRequest>(gposUrl + id, gpo);
}

export const RemoveGpo = (id: number) => {
    return useDeleteHook<number>(gposUrl + id);
}