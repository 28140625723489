import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    StringLengthRule,
    PatternRule
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './datosLector.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import iconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import { enum_estado, enum_tipo } from '../../components/enum/enum';
import { AddLector, GetLector, RemoveLector, UpdateLector } from '../../services/lectores/lectores-service';
import { LectorRequest, newLectorRequest } from '../../types/requests/lectorRequest';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import notify from 'devextreme/ui/notify';
import { AcordeonLectores } from './acordeonLectores';
import { confirm } from 'devextreme/ui/dialog';

interface Params {
    id: string
}

export const DatosLector: FC = () => {
   
    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;
    const { data: lector, isPending, error } = GetLector(idNumero);
 
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        const lectorNuevo: LectorRequest = {
            nombre: String(datos["nombre"]),
            ip: String(datos["ip"]),
            estado: Number(datos["estado"]),
            tipo: Number(datos["tipo"])
        }       

        if (id != null) {
            sendPutSubmit(lectorNuevo);
        } else {
            sendPostSubmit(lectorNuevo);
        }
    };

    const { data: updateLector, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdateLector(idNumero, newLectorRequest);
    const { data: addLector, loading: loadingPost, error: errorPost, sendPostSubmit } = AddLector(newLectorRequest);
    const { data: removeLector, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemoveLector(idNumero);

    useEffect(() => {
        if (updateLector)
            history.push('/datosGenerales');

    }, [updateLector, history]);

    useEffect(() => {
        if (addLector)
            history.push('/lector/' + addLector.id);
    }, [addLector, history]);

    useEffect(() => {
        if (removeLector)
            history.push('/datosGenerales');
    }, [removeLector, history]);

    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])

    const [color, setColor] = useState('black');
    
    useEffect(() => {
        switch (lector?.estado)
        {
            case 1:
                setColor('red');
                break;
            case 2:
                setColor('deepskyblue');
                break;
            case 3:
                setColor('green');
                break;
            default:
                setColor('black');
        }
    }, [lector, color])
    
    const estadoOptions = {
        dataSource: enum_estado,
        valueExpr: "id",
        displayExpr: "nombre",
        disabled: true,
        inputAttr: {'style':'color:' + color}
    }

    const areaEstadoOptions = {
        disabled: true,
        inputAttr: {'style':'background-color:' + color}
    }

    const tipoOptions = {
        dataSource: enum_tipo,
        valueExpr: "id",
        displayExpr: "nombre"        
    };

    function eliminar() {
        let result = confirm("<b>Se va a borrar el lector, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
          });       
    }

    return (
        <div>
            {isPending && <Loading />}
            {error && <Error error={error} />}
            {lector && (
                <div>
                    <h2 className={'content-block'}>Lector</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={lector}>
                            <GroupItem colCount={4}>
                                <SimpleItem render={logoLector}>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField="nombre" >
                                        <StringLengthRule max={255} message={'El nombre no puede ser mayor a 255 caracteres'}></StringLengthRule>
                                        <RequiredRule message="El nombre es requerido" />
                                    </SimpleItem>
                                    <SimpleItem dataField="ip" >
                                        <PatternRule pattern={"(([01]?[0-9]?[0-9])|(2[0-4][0-9])|(25[0-5]))\\.(([01]?[0-9]?[0-9])|(2[0-4][0-9])|(" +
                                    "25[0-5]))\\.(([01]?[0-9]?[0-9])|(2[0-4][0-9])|(25[0-5]))\\.(([01]?[0-9]?[0-9])|(2[" +
                                    "0-4][0-9])|(25[0-5]))"} message={"ERROR de formato"}></PatternRule>
                                        <StringLengthRule max={15} message={'La ip no puede ser mayor de 15 caracteres'}></StringLengthRule>
                                        <RequiredRule message="La ip es requerida" />
                                    </SimpleItem>
                                    <GroupItem colCount={12}>
                                        <SimpleItem colSpan={4}                           
                                            dataField="estado"
                                            editorType="dxSelectBox"
                                            editorOptions={estadoOptions} />
                                        <SimpleItem colSpan={1} 
                                            editorType="dxTextBox" 
                                            editorOptions={areaEstadoOptions}/>
                                        <EmptyItem colSpan={7} />
                                    </GroupItem>
                                    <SimpleItem
                                        dataField="tipo"
                                        editorType="dxSelectBox"
                                        editorOptions={tipoOptions} />
                                </GroupItem>
                            </GroupItem>
                            <GroupItem colCount={12}>
                                <EmptyItem colSpan={10} />
                                <ButtonItem>
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPost || loadingPut)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem>
                                    <ButtonOptions icon={iconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove} />
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                    </form>
                    <br />
                    {idNumero !==0 && <AcordeonLectores idLector={idNumero}/>}
                </div>
            )}
        </div>
    );
}

function logoLector() {
    return (
        <div className="form-logo-lector"></div>
    );
}