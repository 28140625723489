import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoGpis from '../imgIconosTarjetas/iconoGpis.png';
import { GpiType } from '../../../types/gpis';
import { ZonaToNodoType } from '../../../types/zonasToNodos';
import { NodoToLectorType } from '../../../types/nodosToLectores';
import { LectorType } from '../../../types/lectores';
import { NodoType } from '../../../types/nodos';
import { StoreContext } from '../../../contexts/context';
import { AntenaType, GpoType } from '../../../types';
import { GetLectores } from '../../../services/lectores/lectores-service';
import { GetNodos } from '../../../services/nodos/nodos-service';
import { GetGpis } from '../../../services/gpis/gpis-service';
import { GetZonasToNodos } from '../../../services/zonasToNodos/zonasToNodos-service';
import { GetNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import { GetAntena } from '../../../services/antenas/antenas-service';
import { GetGpo } from '../../../services/gpos/gpos-service';

interface Props {
    gpi: GpiType,
    isRelacion: boolean
}

export const TarjetaGpi: FC<Props> = ({ gpi, isRelacion }) => {
    
    const { useHoveredSlice } = useContext(StoreContext);
    const [ hovered, dispatchHovered ] = useHoveredSlice;
    //Seleccionado nodo    
    const { data: lectores } = GetLectores();
    const { data: nodos } = GetNodos();   
    const { data: gpis } = GetGpis();
    const { data: zonasToNodos } = GetZonasToNodos();
    const { data: nodosToLectores } = GetNodosToLectores();
    const { data: antenaSeleccionada } = GetAntena(hovered.antena);
    const { data: gpoSeleccionado } = GetGpo(hovered.gpo);
    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {
        if (gpis !== null)
        {
            const gpiBuscado = (gpis as GpiType[]).find((g: GpiType) => g.nodoId === hovered.nodo && g.id === gpi.id);
            if (gpiBuscado) {
                if (hovered.nodo === gpiBuscado.nodoId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado zona  
        if (zonasToNodos !== null)
        {
            const nodosIdsZona = (zonasToNodos as ZonaToNodoType[]).filter((z2n: ZonaToNodoType) => z2n.zonaId === hovered.zona).map((z2n: ZonaToNodoType) => z2n.nodoId);
            if (nodosToLectores !== null)
            {
                const lectoresIdsNodos = (nodosToLectores as NodoToLectorType[]).filter((n2l: NodoToLectorType) => nodosIdsZona.includes(n2l.nodoId)).map((n2l: NodoToLectorType) => n2l.lectorId);
                if (gpis !== null)
                {
                    const gpis1 = (gpis as GpiType[]).filter((g: GpiType) => lectoresIdsNodos.includes(g.lectorId));
                    const gpiActivo = gpis1.find((g: GpiType) => g.id === gpi.id);
                    if (gpiActivo) {
                        clasescss = "estilo fondoactivado";
                    }
                }
            }
        }

        //Seleccionado lector   
        if (gpis !== null)
        {
            const gpiActivaLector = (gpis as GpiType[]).find((g: GpiType) => g.lectorId === hovered.lector && g.id === gpi.id);
            if (gpiActivaLector) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado Antena 
        if (antenaSeleccionada) {
            if (gpis !== null)
            {
                const gpiActivado = (gpis as GpiType[]).find((x: GpiType) => x.nodoId === (antenaSeleccionada as AntenaType).nodoId && x.id === gpi.id);
                if (gpiActivado) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado Gpo   
        if (gpoSeleccionado) {
            if (gpis !== null)
            {
                const gpiActivadogpo = (gpis as GpiType[]).find((x: GpiType) => x.nodoId === (gpoSeleccionado as GpoType).nodoId && x.id === gpi.id);
                if (gpiActivadogpo) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado gpi    
        if (hovered.gpi === gpi.id) {
            clasescss = "estilo fondoactivado";
        }
    }

    return (        
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarGpi', payload: gpi.id})} onMouseLeave={() => dispatchHovered({type:'actualizarGpi', payload: 0}) } >
            <CardHeader className="headerFont"> Puerto: {gpi.puerto} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoGpis} alt="Card image cap" />
                    <div className="card-vertical" >
                        <CardText className="divididoLineaDescripcion">Flanco: {gpi.flanco}</CardText>
                        <CardText className="divididoLineaDescripcion">Pulso: {gpi.pulso}</CardText>
                        { lectores && 
                        <CardText className="divididoLineaDescripcion">Lector: {(lectores as LectorType[]).filter((x: LectorType) => x.id === gpi.lectorId).map((x: LectorType) => x.nombre)}</CardText>  }
                        { nodos && 
                        <CardText className="divididoLineaDescripcion">Nodo: {(nodos as NodoType[]).filter((x: NodoType) => x.id === gpi.nodoId).map((x: NodoType) => x.nombre)}</CardText>  }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};