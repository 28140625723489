import { UsuarioType } from "../../types/usuarios";
import { ActionUsuario } from "../actions/action-types";

export const usuarioReducer = (state: UsuarioType, action: ActionUsuario): UsuarioType => {
    switch (action.type) {       
         case 'Add':
            return action.payload;
        default:
            return state;
    }
}