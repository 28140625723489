import { useEffect, useState } from "react";
import { ErrorType } from '../types/error';
import { get } from '../services/httpService';

export function useFetch<T>(url: string) {

    const [data, setData] = useState<T | null>(null);
    const [isPending, setIsPending] = useState<boolean>(true);
    const [error, setError] = useState<ErrorType | null>(null);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        setTimeout(() => {
            get<T>(url, signal)
                .then(data => {
                    setIsPending(false);
                    setData(data);
                    setError(null);
                })
                .catch(err => {   
                    if(err.code !== 20) {  //not AbortError: Fetch aborted by user                      
                        setIsPending(false);
                        setError(err);
                    }
                })
        }, 1000);

        return ()=> abortController.abort();

    }, [url])

    return { data, isPending, error };
}

