import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoAntenas from '../imgIconosTarjetas/iconoAntenas.png';
import { AntenaType, LectorType, ZonaToNodoType, NodoToLectorType, NodoType, GpiType, GpoType } from '../../../types';
import { StoreContext } from '../../../contexts/context';
import { GetAntenas } from '../../../services/antenas/antenas-service';
import { GetNodos } from '../../../services/nodos/nodos-service';
import { GetZonasToNodos } from '../../../services/zonasToNodos/zonasToNodos-service';
import { GetNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import { GetLectores } from '../../../services/lectores/lectores-service';
import { GetGpi } from '../../../services/gpis/gpis-service';
import { GetGpo } from '../../../services/gpos/gpos-service';

interface Props {
    antena: AntenaType,
    isRelacion: boolean
}

export const TarjetaAntena: FC<Props> = ({ antena, isRelacion }) => {

    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;

    const { data: antenas } = GetAntenas();
    const { data: nodos } = GetNodos();
    const { data: zonasToNodos } = GetZonasToNodos();
    const { data: nodosToLectores } = GetNodosToLectores();
    const { data: lectores } = GetLectores();
    const { data: gpiSeleccionado } = GetGpi(hovered.gpi);
    const { data: gpoSeleccionado } = GetGpo(hovered.gpo);

    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {
        if (antenas !== null)
        {
            const antenaBuscada = (antenas as AntenaType[]).find(a => a.nodoId === hovered.nodo && a.id === antena.id);
            if (antenaBuscada) {
                if (hovered.nodo === antenaBuscada.nodoId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado zona   
        if (zonasToNodos !== null)
        {
            const nodosIdsZona = (zonasToNodos as ZonaToNodoType[]).filter(z2n => z2n.zonaId === hovered.zona).map(z2n => z2n.nodoId);
            if (nodosToLectores !== null)
            {
                const lectoresIdsNodos = (nodosToLectores as NodoToLectorType[]).filter(n2l => nodosIdsZona.includes(n2l.nodoId)).map(n2l => n2l.lectorId);
                if (antenas !== null)
                {
                    const antenas1 = (antenas as AntenaType[]).filter(a => lectoresIdsNodos.includes(a.lectorId));
                    const antenaActiva = antenas1.find(a => a.id === antena.id);
                    if (antenaActiva) {
                        clasescss = "estilo fondoactivado";
                    }
                }
            }
        }

        //Seleccionado lector   
        if (antenas !== null)
        {
            const antenaActivaLector = (antenas as AntenaType[]).find(a => a.lectorId === hovered.lector && a.id === antena.id);
            if (antenaActivaLector) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado Gpi  
        if (gpiSeleccionado) {
            if (antenas !== null)
            {
                const antGpiActivada = (antenas as AntenaType[]).find(a => a.nodoId === (gpiSeleccionado as GpiType).nodoId && a.id === antena.id);
                if (antGpiActivada) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado Gpo   
        if (gpoSeleccionado) {
            if (antenas !== null)
            {
                const antGpoActivada = (antenas as AntenaType[]).find(a => a.nodoId === (gpoSeleccionado as GpoType).nodoId && a.id === antena.id);
                if (antGpoActivada) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado antena   
        if (hovered.antena === antena.id) {
            clasescss = "estilo fondoactivado";
        }
    }

    return (
         <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarAntena', payload: antena.id})} onMouseLeave={() => dispatchHovered({type:'actualizarAntena', payload: 0}) } >
            <CardHeader className="headerFont"> Puerto: {antena.puerto} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoAntenas} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `5px` }}>
                        <CardText className="divididoLineaDescripcion">Potencia: {antena.potencia}</CardText>
                        <CardText className="divididoLineaDescripcion">Sensibilidad: {antena.sensibilidad}</CardText>
                        { lectores &&
                        <CardText className="divididoLineaDescripcion">Lector: {(lectores as LectorType[]).filter(x => x.id === antena.lectorId).map(x => x.nombre)}</CardText> }
                        { nodos && 
                        <CardText className="divididoLineaDescripcion">Nodo: {(nodos as NodoType[]).filter(x => x.id === antena.nodoId).map(x => x.nombre)}</CardText> }
                    </div>
                </div>               
            </CardBody>
        </Card>
    );
};