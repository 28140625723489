import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoLectores from '../imgIconosTarjetas/iconoLectores.png';
import { enum_estado, enum_tipo } from '../../../components/enum/enum';
import { AntenaType, GpiType, GpoType, LectorType, NodoToLectorType, ZonaToNodoType } from '../../../types';
import { StoreContext } from '../../../contexts/context';
import { GetNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import { GetZonasToNodos } from '../../../services/zonasToNodos/zonasToNodos-service';
import { GetAntenas } from '../../../services/antenas/antenas-service';
import { GetGpi } from '../../../services/gpis/gpis-service';
import { GetGpo } from '../../../services/gpos/gpos-service';

interface Props {
    lector: LectorType,
    isRelacion: boolean
}

export const TarjetaLector: FC<Props> = ({ lector, isRelacion }) => {
  
    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;
    
    //Seleccionado nodo
    const { data: nodosToLectores } = GetNodosToLectores();    
    const { data: zonasToNodos } = GetZonasToNodos();
    const { data: antenas } = GetAntenas();
    const { data: gpiSeleccionado } = GetGpi(hovered.gpi);
    const { data: gpoSeleccionado } = GetGpo(hovered.gpo);

    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {
        //Seleccionado nodo
        if (nodosToLectores !== null)
        {        
            const nodoToLector = (nodosToLectores as NodoToLectorType[]).find(n2l => n2l.lectorId === lector.id && n2l.nodoId === hovered.nodo);
            if (nodoToLector) {
                if (hovered.nodo === nodoToLector.nodoId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado zona   
        if (zonasToNodos !== null)
        {
            const nodosIdsZona = (zonasToNodos as ZonaToNodoType[]).filter(z2n => z2n.zonaId === hovered.zona).map(z2n => z2n.nodoId);
            if (nodosToLectores !== null)
            {
                const nodosToLectoresB = (nodosToLectores as NodoToLectorType[]).filter(n2l => nodosIdsZona.includes(n2l.nodoId));
                const lectoractivo = nodosToLectoresB.find(x => x.lectorId === lector.id);
                if (lectoractivo) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado lector    
        if (hovered.lector === lector.id) {
            clasescss = "estilo fondoactivado";
        }

        //Seleccionado antena   
        if (antenas !== null)
        {
            const antenaActiva = (antenas as AntenaType[]).find(a => a.lectorId === lector.id && a.id === hovered.antena)
            if (antenaActiva) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado Gpi           
        if (gpiSeleccionado) {
            if ((gpiSeleccionado as GpiType).lectorId === lector.id) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado Gpo          
        if (gpoSeleccionado) {
            if ((gpoSeleccionado as GpoType).lectorId === lector.id) {
                clasescss = "estilo fondoactivado";
            }
        }
    }

    return (
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarLector', payload: lector.id})} onMouseLeave={() => dispatchHovered({type:'actualizarLector', payload: 0}) } >
            <CardHeader className="headerFont"> {lector.nombre} </CardHeader>
            <CardBody >
                <div className="card-horizontal">
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoLectores} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px` }}>
                        <CardText className="divididoLineaDescripcion">Ip: {lector.ip}</CardText>
                        <CardText className="divididoLineaDescripcion">Estado: { enum_estado.filter(x => x.id === lector.estado).map(x => x.nombre) }</CardText>
                        <CardText className="divididoLineaDescripcion">Tipo: { enum_tipo.filter(x => x.id === lector.tipo).map(x => x.nombre) }</CardText>
                    </div>                    
                </div>
            </CardBody>
        </Card>

    );
};