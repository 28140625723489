import notify from "devextreme/ui/notify";
import { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../contexts/context";
import { ErrorType } from '../../types/error';

interface Props {
    error: ErrorType
}

export const Error: FC<Props> = ({ error }) => {

    const history = useHistory();  
    const { useTokenSlice } = useContext(StoreContext);
    const [ , dispatchToken ] = useTokenSlice;

    useEffect(() => {
        var a = 'Code: ' + error.code + '\r\n Message: ' + error.message ;
        notify(a, 'Error', 4000);

        if (error.code === 999)
        {
            dispatchToken({type: "Delete" });
            history.push('/'); 
        }
    }, [error, history, dispatchToken])
    
    return null;     
}