
export interface NodoToLectorRequest { 
    nodoId: number
    lectorId: number
}

export const newNodosToLectoresRequest : NodoToLectorRequest = {
    nodoId: 0,
    lectorId: 0
};
