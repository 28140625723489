// Clase propia para construir los metodos para las llamadas al ws
import { antenasUrl } from '../../utils/rutasllamadasapi';
import { AntenaType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { AntenaRequest } from '../../types/requests/antenaRequest';

export const GetAntenas = () => {
    return useFetch<AntenaType[]>(antenasUrl);   
}

export const GetAntena = (id: number) => {    
    return useFetch<AntenaType>(antenasUrl + id);
}

export const AddAntena = (antena: AntenaRequest) => {
    return usePostHook<AntenaType, AntenaRequest>(antenasUrl, antena);
}

export const UpdateAntena = (id: number, antena: AntenaRequest) => {
    return usePutHook<AntenaType, AntenaRequest>(antenasUrl + id, antena);
}

export const RemoveAntena = (id: number) => {
    return useDeleteHook<number>(antenasUrl + id);
}