// Clase propia para construir los metodos para las llamadas al ws
import { permisosUrl } from '../../utils/rutasllamadasapi';
import { PermisoType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { PermisoRequest } from '../../types/requests/permisoRequest';

export const GetPermisos = () => {
    return useFetch<PermisoType[]>(permisosUrl);
}

export const GetPermiso = (id: number) => {
    return useFetch<PermisoType>(permisosUrl + id);
}

export const AddPermiso = (permiso: PermisoRequest) => {
    return usePostHook<PermisoType, PermisoRequest>(permisosUrl, permiso);
}

export const UpdatePermiso = (id: number, permiso: PermisoRequest) => {    
    return usePutHook<PermisoType, PermisoRequest>(permisosUrl + id, permiso);
}

export const RemovePermiso = (id: number) => {
    return useDeleteHook<number>(permisosUrl + id);
}