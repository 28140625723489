import { FC, useEffect, useState } from "react";
import Accordion from 'devextreme-react/accordion';
import { Tarjeta, TarjetaAntena, TarjetaGpi, TarjetaGpo, TarjetaNodo, CardContainer, CheckBoxLectorXNodo, CustomTitle } from '../../components';
import { GetNodos } from "../../services/nodos/nodos-service";
import { GetAntenas } from "../../services/antenas/antenas-service";
import { GetGpis } from "../../services/gpis/gpis-service";
import { GetGpos } from "../../services/gpos/gpos-service";
import { AntenaType, GpiType, GpoType, NodoType } from "../../types";
import { Loading } from "../../components/loading/loading";
import { Error } from "../../components/loading/error";
import { GetNodosToLectores } from "../../services/nodosToLectores/nodosToLectores-service";
import Button from 'devextreme-react/button';
import iconoEditar from '../../components/layouts/imgIconosTarjetas/iconoEditar.png';
import { useHistory } from "react-router-dom";

interface Props {
    idLector: number
}

export const AcordeonLectores: FC<Props> = ({idLector}) => {
    const history = useHistory();
    
    const [acordeon, setAcordeon] = useState([{}]);

    const { data: nodos, isPending: loadingNodos, error: errNodos } = GetNodos();
    const { data: antenas, isPending: loadingAntenas, error: errAntenas } = GetAntenas();
    const { data: gpis, isPending: loadingGpis, error: errGpis } = GetGpis();
    const { data: gpos, isPending: loadingGpos, error: errGpos } = GetGpos();
    const { data: nodosToLectores, isPending: loadingNodosToLectores, error: errNodosToLectores } = GetNodosToLectores();   

    useEffect(() => {
        function onClickButtonEditar(nombre: string, id: number) {
            switch (nombre) {               
                case "Antena":
                    history.push('/antena/' + id);
                    break;
                case "Gpi":
                    history.push('/gpi/' + id);
                    break;
                case "Gpo":
                    history.push('/gpo/' + id);
                    break;
                default:
                    break;
            }
        }

        let tarjetasNodos = null;
        let tarjetasAntenas = null;
        let tarjetasGpis = null;
        let tarjetasGpos = null;

        if (nodos && antenas && gpis && gpos) {
            const renderListadoNodos = (nodo: NodoType) => {
                let resultado = null;
                let nodosToLectoresNodo = nodosToLectores?.find(x => x.nodoId === nodo.id && x.lectorId === idLector);
                resultado = <Tarjeta key={nodo.id}>
                    <TarjetaNodo nodo={nodo} isRelacion={true} />
                    <CheckBoxLectorXNodo nodoToLectorId={nodosToLectoresNodo?.id || 0} lectorId={idLector} nodoId={nodo.id} />
                </Tarjeta>
                return resultado;
            };
            tarjetasNodos = (nodos as NodoType[]).map(nodo => renderListadoNodos(nodo));

            const renderlistadoAntenas = (antena: AntenaType) => {
                let resultado = null;
                resultado = <Tarjeta key={antena.id}>
                    <TarjetaAntena antena={antena} isRelacion={true} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Antena", antena.id)}></Button>
                </Tarjeta>

                return resultado;
            };
            let antenas1 = (antenas as AntenaType[]).filter(a => a.lectorId === idLector);
            tarjetasAntenas = antenas1.map(antena => renderlistadoAntenas(antena));

            const renderlistadoGpis = (gpi: GpiType) => {
                let resultado = null;
                resultado = <Tarjeta key={gpi.id}>
                    <TarjetaGpi gpi={gpi} isRelacion={true} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Gpi", gpi.id)}></Button>
                </Tarjeta>

                return resultado;
            };
            let gpis1 = (gpis as GpiType[]).filter(g => g.lectorId === idLector);
            tarjetasGpis = gpis1.map(gpi => renderlistadoGpis(gpi));

            const renderlistadoGpos = (gpo: GpoType) => {
                let resultado = null;
                resultado = <Tarjeta key={gpo.id}>
                    <TarjetaGpo gpo={gpo} isRelacion={true} />
                    <Button className="editar_centrar_derecha" icon={iconoEditar} onClick={() => onClickButtonEditar("Gpo", gpo.id)}></Button>
                </Tarjeta>

                return resultado;
            };
            let gpos1 = (gpos as GpoType[]).filter(g => g.lectorId === idLector);
            tarjetasGpos = gpos1.map(gpo => renderlistadoGpos(gpo));
        }

        const componentes = [{
            'Title': 'Nodos',
            'children': tarjetasNodos
        }, {
            'Title': 'Antenas',
            'children': tarjetasAntenas
        }, {
            'Title': 'Gpis',
            'children': tarjetasGpis
        }, {
            'Title': 'Gpos',
            'children': tarjetasGpos
        }];

        setAcordeon(componentes);
    }, [nodos, antenas, gpis, gpos, nodosToLectores, idLector, history]);

    return (
        <div>
            {(loadingNodos || loadingAntenas || loadingGpis || loadingGpos || loadingNodosToLectores) && <Loading />}
            {errNodos && <Error error={errNodos} />}
            {errAntenas && <Error error={errAntenas} />}
            {errGpis && <Error error={errGpis} />}
            {errGpos && <Error error={errGpos} />}
            {errNodosToLectores && <Error error={errNodosToLectores} />}
            {(nodos && antenas && gpis && gpos) &&
                <div id="accordion">
                    <Accordion
                        dataSource={acordeon}
                        itemRender={CardContainer}
                        itemTitleRender={CustomTitle}
                        selectedItems={acordeon}
                        multiple={true}
                        collapsible={false}
                        //animationDuration={400}
                    />
                </div>
            }
        </div>
    );
}