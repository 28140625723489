import { FC, useEffect } from 'react';
import Form, {
    ButtonItem,
    ButtonOptions,
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmptyItem,
    StringLengthRule
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import './datosPermiso.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useParams, useHistory } from "react-router-dom";
import iconoEliminar from '../../components/layouts/imgIconosTarjetas/iconoEliminar.png';
import { AddPermiso, GetPermiso, RemovePermiso, UpdatePermiso } from '../../services/permisos/permisos-service';
import { PermisoRequest, newPermisoRequest } from '../../types/requests/permisoRequest';
import { Loading } from '../../components/loading/loading';
import { Error } from '../../components/loading/error';
import { AcordeonRoles } from './acordeonRoles';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

interface Params {
    id: string
}

export const DatosPermiso: FC = () => {

    const history = useHistory();
    const { id } = useParams<Params>();
    const idNumero = id ? parseInt(id, 10) : 0;
    const { data: permiso, isPending, error } = GetPermiso(idNumero);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let campos = new FormData(e.target as HTMLFormElement);
        const datos = Object.fromEntries(campos);

        const permisoNuevo: PermisoRequest = {
            nombre: String(datos["nombre"]),
            descripcion: String(datos["descripcion"])
        }

        if (id != null) {
            sendPutSubmit(permisoNuevo);
        } else {
            sendPostSubmit(permisoNuevo);
        }
    };

    const { data: updatePermiso, loading: loadingPut, error: errorPut, sendPutSubmit } = UpdatePermiso(idNumero, newPermisoRequest);//formData.current);
    const { data: addPermiso, loading: loadingPost, error: errorPost, sendPostSubmit } = AddPermiso(newPermisoRequest);
    const { data: removePermiso, loading: loadingRemove, error: errorRemove, sendRemoveSubmit } = RemovePermiso(idNumero);

    useEffect(() => {
        if (updatePermiso)
            history.push('/datosUsuarios');
    }, [updatePermiso, history]);

    useEffect(() => {
        if (addPermiso)
            history.push('/permisos/'+ addPermiso.id);
    }, [addPermiso, history]);

    useEffect(() => {
        if (removePermiso)
            history.push('/datosUsuarios');
    }, [removePermiso, history]);

    useEffect(() => {
        if (errorPost)
            notify(errorPost.message, 'error', 2000);
    }, [errorPost])
   
    useEffect(() => {
        if (errorPut) 
            notify(errorPut.message, 'error', 2000);
    }, [errorPut])    

    useEffect(() => {
        if (errorRemove)
            notify(errorRemove.message, 'error', 2000);
    }, [errorRemove])
   
    function eliminar() {
        let result = confirm("<b>Se va a borrar el permiso, Desea continuar?</b>", "Confirmar borrar");
        result.then(dialogResult => {
            if (dialogResult) {
                sendRemoveSubmit(); 
            } 
          });       
    }

    return (
        <div>
            {isPending && <Loading />}
            {error && <Error error={error} />}
            {permiso && (
                <div>
                    <h2 className={'content-block'}>Permiso</h2>
                    <form onSubmit={onSubmit}>
                        <Form formData={permiso}>
                            <GroupItem colCount={4}>
                                <SimpleItem render={logoPermiso}>
                                </SimpleItem>
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField="nombre" >
                                        <StringLengthRule max={255} message='El nombre no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                        <RequiredRule message="El nombre es requerido" />
                                    </SimpleItem>
                                    <SimpleItem dataField="descripcion" name="descripcion">
                                        <StringLengthRule max={255} message='La descripcion no puede ser mayor a 255 caracteres' ></StringLengthRule>
                                    </SimpleItem> 
                                </GroupItem>
                            </GroupItem>
                            <GroupItem colCount={12} >
                                <EmptyItem colSpan={10} />
                                <ButtonItem>
                                    <ButtonOptions type={'default'} useSubmitBehavior={true} >
                                        <span className="dx-button-text">
                                            {
                                                (loadingPost || loadingPut)
                                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                                    : 'Guardar'
                                            }
                                        </span>
                                    </ButtonOptions>
                                </ButtonItem>
                                <ButtonItem>
                                    <ButtonOptions icon={iconoEliminar} onClick={eliminar} disabled={idNumero === 0 || loadingRemove} />
                                </ButtonItem>
                            </GroupItem>
                        </Form>
                    </form>
                    <br />
                    { idNumero!==0 && <AcordeonRoles idPermiso={idNumero} /> }
                </div>
            )}
        </div>
    );
}

function logoPermiso() {
    return (
        <div className="form-logo-permiso"></div>
    );
}