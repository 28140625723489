import { FC, useContext, useEffect, useState } from 'react';
import { DashboardControl, Extensions, DataSourceWizard, AjaxRemoteService, DataInspector } from 'devexpress-dashboard-react';
import { TextBoxItemEditorExtension } from 'devexpress-dashboard/designer/text-box-item-editor-extension';
import Button from 'devextreme-react/button';
import { DashboardControlArgs, WorkingMode } from 'devexpress-dashboard';
import { dashboardUrl } from '../../utils/rutasllamadasapi';
//import { useToken } from '../../hooks/token-hook';
import { StoreContext } from '../../contexts/context';
import { useHistory, useParams } from 'react-router-dom';
import { locale } from 'devextreme/localization';
import './dashboard.scss';
import { DeleteDashboardExtension } from './delete-dashboard-extension' 

  interface Params {
    id: string
  }

export const DashboardDev : FC = () => {

    const history = useHistory();
    const { id } = useParams<Params>();     
    const { useUsuarioSlice, useTokenSlice } = useContext(StoreContext);   
    const [ user, ] = useUsuarioSlice;
    //const {token} = useToken(); 
    const [token, dispatchToken] = useTokenSlice;  
    const [headerAuth, setHeaderAuth] = useState({}); 
     
    const tokenlocal = localStorage.getItem("token");
    useEffect(()=>{     
      if (tokenlocal == null)
      {
        //Salir de la aplicacion
        dispatchToken({type: "Delete" });
        history.push('/'); 
      } 
    },[tokenlocal, dispatchToken, history])   

    useEffect(()=>{
        setHeaderAuth({ "Authorization": "Bearer " +  token});
    },[token])

    const [workingModeVar, setWorkingMode] = useState<WorkingMode>("Viewer");
    
    useEffect(() =>{      
      if (user.rolId === 1)
      {
        setWorkingMode("Designer");
      }    
    }, [user]); 
    
    function switchMode(Props: WorkingMode) {
      return Props === 'Viewer' ? "Designer" : "Viewer";
    }
  
    function onBeforeRender(e: DashboardControlArgs){
          var dashboardControl = e.component;                
          dashboardControl.registerExtension(new TextBoxItemEditorExtension(dashboardControl));      
          dashboardControl.registerExtension(new  DeleteDashboardExtension(dashboardControl));
          locale('es');
    }

    return (
      // style={{height: '850px'}}    
      <div style={{height: '850px'}}>     
        { user.rolId === 1 &&
          <Button onClick={() => { setWorkingMode(switchMode(workingModeVar)) }}>Switch to {switchMode(workingModeVar)}</Button> 
        }   
        {user.id !== 0 &&   
          <DashboardControl            
            style={{ height: '100%' }} 
            endpoint= { dashboardUrl }
            workingMode =  {workingModeVar}
            dashboardId = {id}
            onBeforeRender={ onBeforeRender }         
            onOptionChanged = {e => { if(e.name === 'dashboardId') { 
              console.log(e.value);
              history.push("/dashboard/" + (e.value) );
             } } }          
          >                
              <Extensions>                   
                    <DataSourceWizard enableCustomSql={true} />
                    <DataInspector allowInspectAggregatedData={true} allowInspectRawData={true}/>
                    {/* <ViewerApi  /> */}                                      
              </Extensions>
              <AjaxRemoteService
                  headers = { headerAuth }>
              </AjaxRemoteService>                  
          </DashboardControl>
        }
      </div>
    );
}