// Clase propia para construir los metodos para las llamadas al ws
import { nodosUrl } from '../../utils/rutasllamadasapi';
import { NodoType } from '../../types';
import { useFetch } from '../../hooks/fetch-hook';
import { usePostHook } from '../../hooks/post-hook';
import { usePutHook } from '../../hooks/put-hook';
import { useDeleteHook } from '../../hooks/delete-hook';
import { NodoRequest } from '../../types/requests/nodoRequest';

export const GetNodos = () => {
    return useFetch<NodoType[]>(nodosUrl);   
}

export const GetNodo = (id: number) => {
    return useFetch<NodoType>(nodosUrl + id);   
}

export const AddNodo = (nodo: NodoRequest) => {
    return usePostHook<NodoType, NodoRequest>(nodosUrl, nodo);
}

export const UpdateNodo = (id: number, nodo: NodoRequest) => {
    return usePutHook<NodoType, NodoRequest>(nodosUrl + id, nodo);
}

export const RemoveNodo = (id: number) => {
    return useDeleteHook<number>(nodosUrl + id);
}