import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import IconoGpos from '../imgIconosTarjetas/iconoGpos.png';
import { ZonaToNodoType, NodoToLectorType, LectorType, NodoType, AntenaType, GpiType, GpoType } from '../../../types';
import { StoreContext } from '../../../contexts/context';
import { GetLectores } from '../../../services/lectores/lectores-service';
import { GetNodos } from '../../../services/nodos/nodos-service';
import { GetGpos } from '../../../services/gpos/gpos-service';
import { GetZonasToNodos } from '../../../services/zonasToNodos/zonasToNodos-service';
import { GetNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import { GetAntena } from '../../../services/antenas/antenas-service';
import { GetGpi } from '../../../services/gpis/gpis-service';

interface Props {
    gpo: GpoType,
    isRelacion: boolean
}

export const TarjetaGpo: FC<Props> = ({ gpo, isRelacion }) => {

    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;
    
    const { data: lectores } = GetLectores();
    const { data: nodos } = GetNodos();
    const { data: gpos } = GetGpos();
    
    //Seleccionado nodo    
    const { data: zonasToNodos } = GetZonasToNodos();
    const { data: nodosToLectores } = GetNodosToLectores();
    const { data: antenaSeleccionada } = GetAntena(hovered.antena);
    const { data: gpiSeleccionado } = GetGpi(hovered.gpi);
     
    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {
        if (gpos !== null)
        {
            const gpoBuscado = (gpos as GpoType[]).find(g => g.nodoId === hovered.gpo && g.id === gpo.id);
            if (gpoBuscado) {
                if (hovered.gpo === gpoBuscado.nodoId) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado zona   
        if (zonasToNodos !== null)
        {
            const nodosIdsZona = (zonasToNodos as ZonaToNodoType[]).filter((z2n: ZonaToNodoType) => z2n.zonaId === hovered.zona).map((z2n: ZonaToNodoType) => z2n.nodoId);
            if (nodosToLectores !== null)
            {
                const lectoresIdsNodos = (nodosToLectores as NodoToLectorType[]).filter((n2l:NodoToLectorType) => nodosIdsZona.includes(n2l.nodoId)).map((n2l: NodoToLectorType) => n2l.lectorId);
                if (gpos !== null)
                {
                    const gpos1 = (gpos as GpoType[]).filter((g: GpoType) => lectoresIdsNodos.includes(g.lectorId));
                    const gpoActivo = gpos1.find((g: GpoType) => g.id === gpo.id);
                    if (gpoActivo) {
                        clasescss = "estilo fondoactivado";
                    }
                }
            }
        }

        //Seleccionado lector  
        if (gpos !== null)
        {
            const gpoActivaLector = (gpos as GpoType[]).find((g: GpoType) => g.lectorId === hovered.lector && g.id === gpo.id);
            if (gpoActivaLector) {
                clasescss = "estilo fondoactivado";
            }
        }

        //Seleccionado Antena
        if (antenaSeleccionada) {
            if (gpos !== null)
            {
                const gpoActivado = (gpos as GpoType[]).find((g: GpoType) => g.nodoId === (antenaSeleccionada as AntenaType).nodoId && g.id === gpo.id);
                if (gpoActivado) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado Gpi 
        if (gpiSeleccionado) {
            if (gpos !== null)
            {
                const gpoActivadogpi = (gpos as GpoType[]).find((g: GpoType) => g.nodoId === (gpiSeleccionado as GpiType).nodoId && g.id === gpo.id);
                if (gpoActivadogpi) {
                    clasescss = "estilo fondoactivado";
                }
            }
        }

        //Seleccionado gpo  
        if (hovered.gpo === gpo.id) {
            clasescss = "estilo fondoactivado";
        }
    }

    return (      
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarGpo', payload: gpo.id})} onMouseLeave={() => dispatchHovered({type:'actualizarGpo', payload: 0}) } >
            <CardHeader className="headerFont"> Puerto: {gpo.puerto} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoGpos} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px` }}>
                        { lectores && 
                        <CardText className="divididoLineaDescripcion">Lector: {(lectores as LectorType[]).filter((l: LectorType) => l.id === gpo.lectorId).map((l: LectorType) => l.nombre)}</CardText>  }
                        { nodos && 
                        <CardText className="divididoLineaDescripcion">Nodo: {(nodos as NodoType[]).filter((n: NodoType) => n.id === gpo.nodoId).map((n: NodoType) => n.nombre)}</CardText>  }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};