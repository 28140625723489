import { FC, useContext } from 'react';
import { Card, CardHeader, CardImg, CardText, CardBody } from 'reactstrap';
import { StoreContext } from '../../../contexts/context';
import { GetAntenas } from '../../../services/antenas/antenas-service';
import { GetGpis } from '../../../services/gpis/gpis-service';
import { GetGpos } from '../../../services/gpos/gpos-service';
import { GetNodosToLectores } from '../../../services/nodosToLectores/nodosToLectores-service';
import { GetZonasToNodos } from '../../../services/zonasToNodos/zonasToNodos-service';
import { AntenaType, GpiType, GpoType, NodoToLectorType, ZonaToNodoType, ZonaType } from '../../../types';
import IconoZonas from '../imgIconosTarjetas/iconoZonas.png';

interface Props {
    zona: ZonaType,
    isRelacion: boolean
}

export const TarjetaZona : FC<Props> = ({ zona, isRelacion }) => {
  
    const { useHoveredSlice } = useContext(StoreContext);   
    const [ hovered, dispatchHovered ] = useHoveredSlice;

    //Seleccionado Nodo    
    const { data: zonasToNodos } = GetZonasToNodos();   
    const { data: nodosToLectores } = GetNodosToLectores();
    const { data: antenas } = GetAntenas();
    const { data: gpis } = GetGpis();
    const { data: gpos } = GetGpos();
 
    let clasescss = "estilo fondodesactivado";

    if (isRelacion !== true) {
        if (zonasToNodos !== null)
        {
            const zonaToNodo = (zonasToNodos as ZonaToNodoType[]).find(z2n => z2n.zonaId === zona.id && z2n.nodoId === hovered.nodo);
            if (zonaToNodo) {
                if (hovered.nodo === zonaToNodo.nodoId) {
                    if (zonaToNodo.esOrigen) {
                        clasescss = "estilo fondoactivadoOrigen";
                    }
                    else {
                        clasescss = "estilo fondoactivado";
                    }
                }
            }
        }

        //Selecciionado Zona        
        if (hovered.zona === zona.id) {
            clasescss = "estilo fondoactivado";
        }

        //Seleccionado lector       
        if (nodosToLectores !== null)
        {
            const nodosIds2Lectores = (nodosToLectores as NodoToLectorType[]).filter(n2l => n2l.lectorId === hovered.lector).map(n2l => n2l.nodoId);
            if (zonasToNodos !== null)
            {
                const zonasToNodosB = (zonasToNodos as ZonaToNodoType[]).filter(z2n => nodosIds2Lectores.includes(z2n.nodoId));
                const zonaactiva = zonasToNodosB.find(x => x.zonaId === zona.id);
                if (zonaactiva) {
                    if (zonaactiva.esOrigen) {
                        clasescss = "estilo fondoactivadoOrigen";
                    }
                    else {
                        clasescss = "estilo fondoactivado";
                    }
                }
            }
        }

        //Seleccionado antena      
        if (antenas !== null)
        {
            const lectorAntena = (antenas as AntenaType[]).find(a => a.id === hovered.antena);
            if (lectorAntena) {
                if (nodosToLectores !== null)
                {
                    const nodosIds2Lectores1 = (nodosToLectores as NodoToLectorType[]).filter(n2l => n2l.lectorId === lectorAntena.lectorId).map(n2l => n2l.nodoId);
                    if (zonasToNodos !== null)
                    {
                        const zonasToNodosBu = (zonasToNodos as ZonaToNodoType[]).filter(z2n => nodosIds2Lectores1.includes(z2n.nodoId));
                        const zonaactiva1 = zonasToNodosBu.find(x => x.zonaId === zona.id);
                        if (zonaactiva1) {
                            if (zonaactiva1.esOrigen) {
                                clasescss = "estilo fondoactivadoOrigen";
                            }
                            else {
                                clasescss = "estilo fondoactivado";
                            }
                        }
                    }
                }
            }
        }

        //Seleccionado gpi   
        if (gpis !== null)
        {    
            const gpisAntena = (gpis as GpiType[]).find(a => a.id === hovered.gpi);
            if (gpisAntena) {
                if (nodosToLectores !== null)
                {
                    const nodosIds2Lectores1 = (nodosToLectores as NodoToLectorType[]).filter(n2l => n2l.lectorId === gpisAntena.lectorId).map(n2l => n2l.nodoId);
                    if (zonasToNodos !== null)
                    {
                        const zonasToNodosBu = (zonasToNodos as ZonaToNodoType[]).filter(z2n => nodosIds2Lectores1.includes(z2n.nodoId));
                        const zonaactiva1 = zonasToNodosBu.find(x => x.zonaId === zona.id);
                        if (zonaactiva1) {
                            if (zonaactiva1.esOrigen) {
                                clasescss = "estilo fondoactivadoOrigen";
                            }
                            else {
                                clasescss = "estilo fondoactivado";
                            }
                        }
                    }
                }
            }
        }

        //Seleccionado gpo  
        if (gpos !== null)
        {
            const gposAntena = (gpos as GpoType[]).find(a => a.id === hovered.gpo);
            if (gposAntena) {
                if (nodosToLectores !== null)
                {
                    const nodosIds2Lectores1 = (nodosToLectores as NodoToLectorType[]).filter(n2l => n2l.lectorId === gposAntena.lectorId).map(n2l => n2l.nodoId);
                    if (zonasToNodos !== null)
                    {
                        const zonasToNodosBu = (zonasToNodos as ZonaToNodoType[]).filter(z2n => nodosIds2Lectores1.includes(z2n.nodoId));
                        const zonaactiva1 = zonasToNodosBu.find(x => x.zonaId === zona.id);
                        if (zonaactiva1) {
                            if (zonaactiva1.esOrigen) {
                                clasescss = "estilo fondoactivadoOrigen";
                            }
                            else {
                                clasescss = "estilo fondoactivado";
                            }
                        }
                    }
                }
            }
        }
    }

    return (   
        <Card className={clasescss} onMouseEnter={() => dispatchHovered({type:'actualizarZona', payload: zona.id})} onMouseLeave={() => dispatchHovered({type:'actualizarZona', payload: 0}) } >
            <CardHeader className="headerFont"> {zona.nombre} </CardHeader>
            <CardBody >
                <div className="card-horizontal" >
                    <CardImg style={{ width: `100px`, height: `80px` }} src={IconoZonas} alt="Card image cap" />
                    <div className="card-vertical" style={{ margin: `10px` }}>
                        <CardText className="divididoLineaDescripcion">{zona.descripcion}</CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};